import React, { useEffect, useRef, useState } from 'react'
import { HiBell } from 'react-icons/hi'
import { assets } from '../../general/assets'
import { useDispatch } from 'react-redux';
import { GetRequest } from '../../services/apiService';
import apiEndPoints from '../../services/apiEndPoints';
import { FaCircle } from 'react-icons/fa';

export default function NotificationsDropdown({ reloader, showHeaderDropDown, setShowHeaderDropDown }) {
    const [notificationList, setNotificationList] = useState([]);
    const [unreadNotif, setUnreadNotif] = useState(false);
    const [reloadNotif, setReloadNotif] = useState(false);

    const dispatch = useDispatch()
    const timerRef = useRef(null);

    async function getNotif() {
        const resp = await GetRequest(apiEndPoints.notification.short)
        if (resp && resp.data && resp.data.status == "success") {
            console.log('resp: ', resp.data.data);
            if ((resp.data.data).length >= 1) {
                setNotificationList(resp.data.data);
                setUnreadNotif(false);
                (Array(...resp.data.data).forEach((value) => {
                    if (!value.isread) {
                        setUnreadNotif(true);
                    }
                }))
                console.log('notificationList', notificationList)
                return;
            }
        }
        setNotificationList([{ id: '-123', message: 'No notifications', isread: true, timegap: '' }])
    }

    useEffect(() => {
        getNotif();
        //clear the interval when the component unmounts
        return () => clearInterval(timerRef.current);
    }, [])

    useEffect(() => {
        setReloadNotif(reloader);
        console.log('reloader sent');
    }, [reloader])

    useEffect(() => {
        clearInterval(timerRef.current)
        getNotif();
        timerRef.current = setInterval(() => {
            setReloadNotif((9 - reloadNotif) % 5);
            console.log('timeout hit');
        }, 600000) //every 10 mins
    }, [reloadNotif])

    return (
        <div className='relative'>
            <button
                onClick={() => {
                    const status = showHeaderDropDown == "notifications" ? "" : "notifications"
                    setShowHeaderDropDown(status)
                }}
                className="inline-flex items-center text-sm font-medium text-center text-gray-500 hover:text-gray-900 focus:outline-none dark:hover:text-white dark:text-gray-400" type="button">
                <HiBell size="28" color="#444444" />
                <div className="relative flex">
                    <div className={"relative inline-flex w-3 h-3 rounded-full -top-2 right-3 border-2 " + (unreadNotif ? 'bg-red-500 border-white dark:border-gray-900' : 'border-transparent bg-transparent')}></div>
                </div>
            </button>
            {showHeaderDropDown == "notifications" &&
                <div className="absolute z-20  w-[400px] right-0 max-w-sm bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-800 dark:divide-gray-700" aria-labelledby="dropdownNotificationButton">
                    <div className="block px-4 py-2 font-medium text-center text-gray-700 rounded-t-lg bg-gray-50 dark:bg-gray-800 dark:text-white">
                        Notifications
                    </div>
                    <div className="divide-y divide-gray-100 dark:divide-gray-700">
                        {notificationList && Object.keys(notificationList).map((key) => {
                            const val = notificationList[key];
                            return (
                                <a href={(val.id && val.id > 0) ? '/notifications/' + val.id : '#'} className="block px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-700" key={key}>

                                    {(val.id != -123) ?
                                        <div className="flex">
                                            <div className="shrink my-auto">
                                                {(!!val.isread) ? <FaCircle color="green" /> : <FaCircle color="red" />}
                                            </div>
                                            <div className="w-full pl-3 h-12 align-middle">
                                                <div className="text-gray-500 text-sm mb-1.5 dark:text-gray-400 text-ellipsis overflow-x-hidden whitespace-nowrap pr-3 h-6">{val.message}</div>
                                                {(!!val.timegap) ? <div className="text-xs text-blue-600 dark:text-blue-500">{val.timegap}</div> : <></>}
                                            </div>
                                        </div>
                                        :
                                        <div className="w-full h-12 max-h-12 align-middle overflow-hide">
                                            <div className="text-gray-500 dark:text-gray-400 my-3">
                                                {val.message}
                                            </div>
                                        </div>
                                    }
                                </a>)
                        })
                        }
                    </div>
                    <a href="/notifications" className="block py-2 text-sm font-medium text-center text-gray-900 rounded-b-lg bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-white">
                        <div className="inline-flex items-center ">
                            View all
                        </div>
                    </a>
                </div>
            }
        </div>
    )
}
