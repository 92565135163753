import React from 'react'
import { NavLink } from 'react-router-dom'

export default function MenuItem({ data, showFullMenu }) {
    return (
        <li className='p-3'>
            <NavLink to={data.link} className={({ isActive, isPending }) => {
                // console.log(data.name, isActive)
                const activeClass = isActive ? 'text-black' : 'text-gray-700';
                return activeClass + " flex items-center font-semibold hover:text-black"
            }}>
                <span className='mr-2 text-xl'>{data.icon}</span>
                {showFullMenu && <span className=' text-lg'>{data.name}</span>}
            </NavLink>
        </li>
    )
}
