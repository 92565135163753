import { Field } from 'formik'
import React, { useState } from 'react'
import TextInput from './textInput'
import { AiOutlinePlus } from 'react-icons/ai'
import { IoIosClose } from 'react-icons/io'

export default function SelectOptions({ options, setOptions }) {
  const [error, setError] = useState("")
  const [newOption, setNewOption] = useState("")

  const addOption = () => {
    if (newOption.trim().length == 0) {
      setError("Please enter a valid value")
      return;
    }
    setOptions([...options, newOption])
    setNewOption("")
    setError("")
  }


  const removeOption = (value) => {
    options = options.filter((option) => {
      return option != value;
    })
    setOptions(options)
  }

  const errorBorder = error ? "border-red-300" : "border-gray-300";

  return (
    <div className="w-full p-5 mb-3 bg-gray-100 rounded-md">
      <div className='mb-3 '>
        <div className="flex items-end">
          <div className="w-2/3">
            <label htmlFor="addOption" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Add Option</label>
            <input
              value={newOption}
              className={errorBorder + " h-12 bg-gray-50 border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"}
              placeholder="Enter option" onChange={(e) => { setNewOption(e.target.value) }} />

          </div>
          <div className="w-1/3">
            <button className="bg-blue-400 hover:bg-blue-700 h-12 ml-3 pl-3 pr-5 rounded-lg text-md text-white font-bold" type="button" onClick={addOption}><AiOutlinePlus className='inline-block' size={18} /> Add</button>
          </div>
        </div>
        {error && <div className="text-red-500">{error}</div>}
      </div>
      {options && <ul className=''>
        {options.map((option, index) => {
          return <li className='inline-flex bg-blue-400 mr-2 rounded-3xl py-2 px-4 text-white mb-2' key={"option-" + index}>
            <span className='capitalize'>{option}</span>
            <button type="button" className=" ml-2 rounded-full text-lg hover:bg-red-600 hover:text-white" onClick={() => { removeOption(option) }}><IoIosClose size="20" /></button>
          </li>
        })}
      </ul>
      }
    </div>
  )
}
