import React from 'react'

export default function TextAreaInput({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) {
  const classes = props.input.fullWidth ? "w-full mb-3 md:col-span-3 " : "w-full mb-3";
  return (
    <div className={classes}>
      <label htmlFor={props.label.for} className={props.label.classes ?? "block mb-2 text-sm font-semibold text-gray-900 dark:text-white"}>{props.label.text}</label>
      <textarea {...field} id={props.input.id}
        className={props.input.classes ?? " bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"}
        placeholder={props.input.placeholder ?? ""} rows={5}></textarea>
      {touched[field.name] &&
        errors[field.name] && <div className="text-red-500">{errors[field.name]}</div>}
    </div>
  )
}
