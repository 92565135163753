import { createSlice } from '@reduxjs/toolkit'

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    showLoading: false
  },
  reducers: {
    toggleLoading: (state, action) => {
      console.log("Toggle Loading action: ", action)
      state.showLoading = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { toggleLoading } = loadingSlice.actions

export default loadingSlice.reducer
