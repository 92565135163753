import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import MainLayout from '../layouts/mainLayout'
import Dashboard from '../pages/dashboard'
import Login from '../pages/Auth/Login'
import Standards from '../pages/standards/standards'
import AddStandard from '../pages/standards/addStandard'
import StandardDetails from '../pages/standards/standardDetails'
import StandardsLogs from '../pages/logs/standardsLogs'
import AddLog from '../pages/logs/addLog'
import LogDetails from '../pages/logs/logDetails'
import Users from '../pages/users/users'
import AddUser from '../pages/users/addUser'
import EditUser from '../pages/users/editUser'
import { GetRequest, PostRequest } from '../services/apiService'
import apiEndPoints from '../services/apiEndPoints'
import { logIn, logOut } from '../slices/authenticationSlice'
import Logout from '../pages/Auth/Logout'
import UserProfile from '../pages/users/profile'
import appDataHelper from '../data/appDataHelper'
import Reports from '../pages/reports/reports'
import NotificationIndex from '../pages/notifications/notifications'
import ShowNotification from '../pages/notifications/showNotifications'
import LogDates from '../pages/reports/logDates/logDates'
import Assets from '../pages/reports/assets/assets'

export default function AppRoutes() {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="logout" element={<Logout />} />
      <Route path="dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
      <Route path="standards" element={<RequireAuth role={appDataHelper.pagePermissions.admin}><Standards /></RequireAuth>} />
      <Route path="standards/add" element={<RequireAuth role={appDataHelper.pagePermissions.admin}><AddStandard /></RequireAuth>} />
      <Route path="standards/:id" element={<RequireAuth role={appDataHelper.pagePermissions.admin}><StandardDetails /></RequireAuth>} />
      <Route path="logs" element={<RequireAuth role={appDataHelper.pagePermissions.staff} ><StandardsLogs /></RequireAuth>} />
      <Route path="logs/add" element={<RequireAuth role={appDataHelper.pagePermissions.staff}><AddLog /></RequireAuth>} />
      <Route path="logs/:id" element={<RequireAuth role={appDataHelper.pagePermissions.staff}><LogDetails /></RequireAuth>} />
      <Route path="notifications" element={<RequireAuth role={appDataHelper.pagePermissions.staff}><NotificationIndex /></RequireAuth>} />
      <Route path="notifications/:id" element={<RequireAuth role={appDataHelper.pagePermissions.staff}><ShowNotification /></RequireAuth>} />
      <Route path="reports" element={<RequireAuth role={appDataHelper.pagePermissions.admin}><Reports /></RequireAuth>} />
      <Route path="reports/logDates" element={<RequireAuth role={appDataHelper.pagePermissions.admin}><LogDates /></RequireAuth>} />
      <Route path="reports/assets" element={<RequireAuth role={appDataHelper.pagePermissions.admin}><Assets /></RequireAuth>} />
      <Route path="users" element={<RequireAuth role={appDataHelper.pagePermissions.manager}><Users /></RequireAuth>} />
      <Route path="users/add" element={<RequireAuth role={appDataHelper.pagePermissions.manager}><AddUser /></RequireAuth>} />
      <Route path="users/:id" element={<RequireAuth role={appDataHelper.pagePermissions.manager}><EditUser /></RequireAuth>} />
      <Route path="profile" element={<RequireAuth role={appDataHelper.pagePermissions.staff}><UserProfile /></RequireAuth>} />
    </Routes>
  )
}

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
}

// const refreshToken = async (dispatch) => {

//   const resp = await GetRequest(apiEndPoints.authentication.me, {})
//   if (resp && resp.status === 401) {
//     // const refResp = await GetRequest(apiEndPoints.authentication.refresh, {});
//     // if (refResp && refResp.data && refResp.data.status == 'success') {
//     //   localStorage.setItem('AppData', JSON.stringify({ user: refResp.data.data, isLoggedIn: true }))
//     //   dispatch(logIn({ user: refResp.data.data, isLoggedIn: true }))
//     // } else {
//     localStorage.removeItem('AppData')
//     dispatch(logOut())
//     //}
//   }

// }

const RequireAuth = ({ children, role }) => {
  const authentication = useSelector(state => state.authentication)
  console.log(authentication)
  let location = useLocation();
  const dispatch = useDispatch()

  //check if token is valid
  if (authentication && authentication.user && authentication.user.token) {
    if (parseJwt(authentication.user.token).exp <= Math.floor(Date.now() / 1000)) {
      console.warn('token expired');
      dispatch(logOut());
      localStorage.removeItem('AppData');
    }
  }


  //redirect to login if not authenticated
  if (!authentication.isLoggedIn) {
    return <Navigate to="/" state={{ from: location }} replace={false} />;
  }

  //redirect to dashboard if the user role doesn't have access
  if (role && !role.includes(authentication.user.role)) {
    // console.log('authentication.user.role != role;', authentication.user.role, '; ', role)
    return <Navigate to="/dashboard" state={{ from: location }} replace={true} />;
  }

  return children;
}