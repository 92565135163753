import React, { useEffect, useState } from 'react'

export default function NumberInput({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) {
  return (
    <div className="w-full mb-3">
      <label htmlFor={props.label.for} className={props.label.classes ?? "block mb-2 text-sm font-medium text-gray-900 dark:text-white"}>{props.label.text}</label>
      <input {...field} id={props.input.id} type='number' onChange={(e) => { field.onChange(e); if (props.apiErrors) props.apiErrors.change(); }}
        className={props.input.classes ?? " h-12 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"}
        placeholder={props.input.placeholder ?? ""} />
      {touched[field.name] &&
        errors[field.name] && <div className="text-red-500">{errors[field.name]}{console.log(errors)}</div>}
      {props.apiErrors && props.apiErrors.error && (props.apiErrors.error !== "") && <div className="text-red-500">{props.apiErrors.error}{console.log(errors)}</div>}
    </div>
  )
}
