import React, { useEffect, useState } from 'react'
import MainLayout from '../../layouts/mainLayout'
import Breadcrumb from '../../components/layout/breadcrumb'
import PageTitle from '../../components/paritals/pageTitle'
import PageHeader from '../../components/paritals/pageHeader'
import HeaderActionButton from '../../components/paritals/headerActionButton'
import { Field, Formik, Form } from 'formik'
import TextInput from '../../components/form/textInput'
import FileInput from '../../components/form/fileInput'
import SelectInput from '../../components/form/selectInput'
import TextAreaInput from '../../components/form/textAreaInput'
import * as Yup from 'yup';
import InputField from '../../components/form/InputField'
import { createYupSchema } from '../../helpers/yupSchemaGenerator'
import apiEndPoints from '../../services/apiEndPoints'
import { toggleLoading } from '../../slices/loadingSlice'
import { useDispatch } from 'react-redux'
import { GetRequest, PostRequest } from '../../services/apiService'
import { useNavigate, useParams } from 'react-router-dom'
import Errors from '../../components/common/errors'

export default function EditUser() {
  const [errors, setErrors] = useState([])
  const [initialValues, setInitialValues] = useState({})
  const [user, setUser] = useState({})
  const [managerList, setManagerList] = useState([])

  const formInputs = [
    {
      input_id: 1,
      title: "First Name",
      uuid: "first_name",
      details: "Enter user first name",
      inputType: 'text',
      variable: true,
      required: true,
      baseValue: 0,
      minVariation: 1,
      maxVariaition: 2,
      validationType: "string",
      validations: [
        {
          type: "required",
          params: ["First name is required"]
        }
      ]
    },
    {
      input_id: 2,
      title: "Last Name",
      uuid: "last_name",
      details: "Enter user last name",
      inputType: 'text',
      variable: true,
      required: true,
      baseValue: 0,
      minVariation: 1,
      maxVariaition: 2,
      validationType: "string",
      validations: [
        {
          type: "required",
          params: ["Last name is required"]
        }
      ]
    },
    {
      input_id: 3,
      title: "Employee Number",
      uuid: "employee_number",
      details: "Enter employee number",
      inputType: 'text',
      variable: true,
      required: true,
      baseValue: 0,
      minVariation: 1,
      maxVariaition: 2,
      validationType: "string",
      validations: []
    },
    {
      input_id: 4,
      title: "Email",
      uuid: "email",
      details: "Enter user email",
      inputType: 'text',
      variable: true,
      required: true,
      baseValue: 0,
      minVariation: 1,
      maxVariaition: 2,
      validationType: "string",
      validations: [
        {
          type: "required",
          params: ["Email is required"]
        }
      ]
    },
    {
      input_id: 5,
      title: "Password",
      uuid: "password",
      details: "Enter user password",
      inputType: 'password',
      variable: true,
      required: true,
      baseValue: 0,
      minVariation: 1,
      maxVariaition: 2,
      validationType: "string",
      validations: [

      ]
    },
    {
      input_id: 6,
      title: "Confirm Password",
      uuid: "password_confirmation",
      details: "Confirm user password",
      inputType: 'password',
      variable: true,
      required: true,
      baseValue: 0,
      minVariation: 1,
      maxVariaition: 2,
      validationType: "string",
      validations: [

      ]
    },
    {
      input_id: 1,
      title: "Status",
      uuid: "active",
      details: "Account Status",
      inputType: 'select',
      options: [
        {
          label: "Active",
          value: "1"
        },
        {
          label: "Inactive",
          value: "0"
        }
      ],
      variable: false,
      required: true,
      validationType: "string",
      validations: [
        {
          type: "required",
          params: ["Choose account status"]
        }
      ]
    },
    {
      input_id: 1,
      title: "User Role",
      uuid: "role",
      details: "Choose user role",
      inputType: 'select',
      options: [
        {
          label: "Staff",
          value: "staff"
        },
        {
          label: "Manager",
          value: "manager"
        },
        {
          label: "Admin",
          value: "admin"
        }
      ],
      variable: false,
      required: true,
      validationType: "string",
      validations: [
        {
          type: "required",
          params: ["Choose a user role"]
        }
      ]
    }
  ]

  //
  const dispatch = useDispatch()
  const navigate = useNavigate();

  //
  let { id } = useParams();

  //
  const setLoading = (show = true) => {
    dispatch(toggleLoading(show))
  }

  const submitUser = async (data) => {
    setLoading(true)
    const formData = new FormData();
    Object.entries(data).map((input, index) => {
      formData.append(input[0], input[1])
    })

    //
    const resp = await PostRequest(apiEndPoints.users.index + "/" + user.id, { data: formData })
    setLoading(false)
    if (resp.data.status == "success") {
      alert("User updated successfully.")
      setUser(resp.data.data)
      navigate("/users/" + resp.data.data.id)
    } else {
      setErrors(resp.data.errors)
    }
  }

  //
  const getInitalData = async () => {
    //
    setLoading(true)
    const resp = await GetRequest(apiEndPoints.users.index + "/" + id)
    if (resp.data.status == "success") {

      //
      const userDetails = resp.data.data;
      console.log(userDetails)
      setUser({ ...userDetails })

      //
      const values = {}
      formInputs.map((input, index) => {
        values[input.uuid] = userDetails?.[input.uuid] ?? ""
      })
      values["manager"] = userDetails?.["manager_id"] ?? ""

      console.log(values)
      setInitialValues({ ...values, active: (values.active ? 1 : 0) });

    } else {
      setErrors(resp.data.errors)
    }
    setLoading(false)
  }

  const getManagerList = async () => {
    setLoading(true);
    const resp = await GetRequest(apiEndPoints.users.managerlist)

    if (resp.data.status == "success") {
      if (resp.data.data) {
        setManagerList({ ...resp.data.data });
        console.log('managerlist: ', resp.data.data, managerList)
      }
    } else {
      setErrors(resp.data.errors)
    }
    setLoading(false);
  }

  const getManagerOptions = () => {
    let allOptions = [];
    allOptions.push({ label: 'Unselected', value: '' })
    Object.keys(managerList).map((item) => {
      allOptions.push({ label: managerList[item].label, value: managerList[item].id })
    })
    return allOptions;
  }

  //
  useEffect(() => {
    getInitalData()
    getManagerList()
  }, [])

  const yepSchema = formInputs.reduce(createYupSchema, {});
  const validationSchema = Yup.object().shape(yepSchema);

  return (
    <MainLayout>
      <div className='p-3'>
        <Breadcrumb links={breadcrumbLinks} />
        <div>
          <PageHeader>
            <PageTitle title={"Edit User - " + user.first_name + " " + user.last_name} />
            <HeaderActionButton />
          </PageHeader>
          <div className="bg-white border border-gray-200 relative overflow-x-auto shadow-md sm:rounded-lg">
            <Errors errors={errors} />
            <div className="p-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(data, actions) => {
                  submitUser(data)
                }}
              >
                {(props) => (
                  <Form>
                    <div className='grid grid-cols-1 gap-4 md:grid-cols-3'>
                      {
                        formInputs && formInputs.map((input, index) => {
                          return <InputField inputData={input} key={"user-inpt-field-" + index} />
                        })}
                      {props.values.role == 'staff' && (<InputField inputData={{
                        input_id: 9,
                        title: "Manager",
                        uuid: "manager",
                        details: "Choose Manager",
                        inputType: 'select',
                        options: getManagerOptions(),
                        variable: false,
                        required: true,
                        validationType: "string",
                        validations: [
                          {
                            type: "required",
                            params: ["Choose a manager"]
                          }
                        ]
                      }} key={"user-inpt-field-" + 9} />)}
                    </div>
                    <div className='mt-5 mb-16'>
                      <button type="submit" className="inline-flex items-center text-white bg-blue-700 font-semibold hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 rounded-lg text-md px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        <span className='text-lg font-semibold'>Submit</span>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

const breadcrumbLinks = [
  {
    href: "/users",
    label: "Users"
  },
  {
    label: "Details"
  }
]
