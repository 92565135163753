import logo from "../assets/logo.png"
import loginBgImg from "../assets/login.jpg"
import profileImagePlaceholder from "../assets/profile-placeholder.png"

const assets = {
    logo: logo,
    loginBgImg: loginBgImg,
    profileImagePlaceholder:profileImagePlaceholder
}

export {
    assets
}