import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MainLayout from '../../layouts/mainLayout'
import Breadcrumb from '../../components/layout/breadcrumb'
import PageTitle from '../../components/paritals/pageTitle'
import PageHeader from '../../components/paritals/pageHeader'
import { GetRequest, PostRequest } from '../../services/apiService'
import apiEndPoints from '../../services/apiEndPoints'
import { toggleLoading } from '../../slices/loadingSlice'
import { Field, Form, Formik } from 'formik';
import FilterTextInput from '../../components/dashboard/filterTextInput';
import MultiSelectInput from '../../components/form/multiSelectInput';
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa';

export default function Reports() {
  const [assetList, setAssetList] = useState([]);
  const [failedAL, setFailedAL] = useState(false);

  const [reports, setReports] = useState({
    standards: [{ id: 0, part_name: '', part_number: '', resin: '', rev: '', logs: {}, inputs: {} }],
    //    date_start: new Date(2023, 8, 1).toISOString().split('T')[0], /*    TODO: REMOVE THIS, THIS IS FOR TESTING. use the real one, below     */
    //    date_end: new Date(2023, 9, 12).toISOString().split('T')[0] /*    TODO: REMOVE THIS, THIS IS FOR TESTING. use the real one, below    */
    date_start: new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1).toISOString().split('T')[0],
    date_end: new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0).toISOString().split('T')[0]
  });


  const validationSchema = Yup.object().shape({
    date_start: Yup.date().required('Required'),
    date_end: Yup.date().required('Required'),
    selectedAssets: Yup.array().required('Required').min(1, 'Please select at least 1 asset').max(10, 'Too many assets, please select 10 or fewer assets!')
  });

  //functionality for asset list searchbar start
  const assetAlteredInputList = [
    { replace: { old: 'Part Name: "', new: "" } },
    { replace: { old: '",' + "\n" + 'Part Number: "', new: "|||" } },
    { replace: { old: '",' + "\n" + 'Resin: "', new: "|||" } },
    { replace: { old: '",' + "\n" + 'Rev: "', new: "|||" } },
    { trimEnd: 1 }
  ]
  const assetAlteredInputSearch = {
    type: 'split',
    splitChars: '|||',
    filterInputFields:
    {
      'name': 0,
      'part name': 0,
      'number': 1,
      'part number': 1,
      'resin': 2,
      'rev': 3,
    }
  }
  const assetAlterInputSelectedLabel = ((label) => {
    let newLabel = label.trim();
    if (!(newLabel.includes('Part Name: "') && newLabel.includes('",' + "\n" + 'Part Number: "'))) return label;

    newLabel = newLabel.split('Part Name: "');
    if (newLabel.length < 2) return label;
    newLabel = newLabel[1].split('",' + "\n" + 'Part Number: "')[0];

    return newLabel;
  })
  //functionality for asset list searchbar end

  const dispatch = useDispatch()
  const setLoading = (show = true) => {
    dispatch(toggleLoading(show))
  }

  async function getAssetList() {
    setLoading(true);
    let url = apiEndPoints.reports.assets;
    const resp = await GetRequest(url)
    if (resp && resp.data && resp.data.status == "success") {
      console.log('resp assetlist ', resp.data.data);
      setAssetList(resp.data.data.options);
      setFailedAL(false);
    } else {
      setFailedAL(true);
    }
    setLoading(false);
  }

  //filter fields updating values when going outside date range
  const maxFilterDayGap = 42;
  let filterFieldsInProgress = false;
  //trigger 1ms timeframe so they don't yo-yo
  function resetFilterFieldsInProgress() { setTimeout(() => { filterFieldsInProgress = false; }, 1); }
  function limitFilterFields(field_name, field_original_value, form_date_start, form_date_end, changingFunction) {
    if (filterFieldsInProgress && filterFieldsInProgress !== field_name) return;
    if ((field_name === 'date_start' && field_original_value === form_date_start) ||
      (field_name === 'date_end' && field_original_value === form_date_end)) return;
    filterFieldsInProgress = field_name;

    let partialDate = form_date_start.split('-');
    const start_time = new Date(partialDate[0], partialDate[1] - 1, partialDate[2]);
    partialDate = form_date_end.split('-');
    const end_time = new Date(partialDate[0], partialDate[1] - 1, partialDate[2]);

    switch (field_name) {
      default:
        filterFieldsInProgress = false;
        return;
      case 'date_start':
        const maxGapStartDate = new Date(start_time.getFullYear(), start_time.getMonth(), start_time.getDate() - 1 + maxFilterDayGap);

        if (end_time.getTime() > maxGapStartDate.getTime() || end_time.getTime() <= start_time.getTime()) {
          changingFunction('date_end', maxGapStartDate.toISOString().slice(0, 10));
          //console.log('ess', start_time.toISOString().slice(0, 10), maxGapStartDate.toISOString().slice(0, 10));

          resetFilterFieldsInProgress();
          return;
        }
        filterFieldsInProgress = false;
        return;
      case 'date_end':
        const maxGapEndDate = new Date(end_time.getFullYear(), end_time.getMonth(), end_time.getDate() + 1 - maxFilterDayGap);

        if (start_time.getTime() < maxGapEndDate.getTime() || start_time.getTime() >= end_time.getTime()) {
          changingFunction('date_start', maxGapEndDate.toISOString().slice(0, 10));
          console.log('eds', end_time.toISOString().slice(0, 10), maxGapEndDate.toISOString().slice(0, 10))

          resetFilterFieldsInProgress();
          return;
        }

        filterFieldsInProgress = false;
        return;
    }
  }

  const processFilterChange = async (values) => {
    setLoading(true);
    //setErrors({});
    const resp = await PostRequest(apiEndPoints.reports.getReports, { data: values });
    //console.log('filter response: ', resp)
    if (resp && resp.data && resp.data.status) {
      if (resp.data.errors) {
        console.warn('Error when loading data: ', resp.data.errors)
      }
      if (resp.data.status === 'success') {
        setReports({ ...resp.data.data });

        console.log('success', resp.data.data);
      }
    }
    setLoading(false);
  }

  const getXlsxFromServer = async (exportAssetId) => {
    if (typeof (exportAssetId) !== 'string') { return false; }
    setLoading(true);
    //setErrors({});

    let exportAssetList = [];
    if (exportAssetId === 'all') {
      Object.keys(reports.standards).forEach(function (key) {
        exportAssetList.push(reports.standards[key].id);
      });
    } else if (!isNaN(exportAssetId) && !isNaN(parseInt(exportAssetId))) {
      exportAssetList.push(exportAssetId);
    }

    const resp = await PostRequest(apiEndPoints.reports.getReportFiles, {
      data: {
        "date_start": reports.date_start,
        "date_end": reports.date_end,
        "selectedAssets": exportAssetList,
      }
    });

    if (resp && resp.data && resp.data.status) {
      //console.log('filereq response: ', resp.data.data)

      let fileDL = document.createElement('a');
      fileDL.href = resp.data.data.file;
      fileDL.setAttribute("download", resp.data.data.filename);
      fileDL.click();
    }

    setLoading(false);
  }

  useEffect(() => {
    getAssetList(true);
  }, [])

  return (
    <MainLayout>
      <div className='p-3'>
        <Breadcrumb links={breadcrumbLinks} />
        <div>
          <PageHeader>
            <PageTitle title="Reports" />
          </PageHeader>
          {assetList.length > 0 ?
            (<div>
              <div>
                <Formik //filters
                  validationSchema={validationSchema}
                  initialValues={{ date_start: reports.date_start, date_end: reports.date_end, selectedAssets: [] }}
                  onSubmit={(values) => {
                    processFilterChange(values)
                  }}
                >
                  {(props) => (
                    <Form>
                      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 gap-y-0 mb-2'>
                        <Field name="date_start" label={{ for: "date_start", text: "Start:" }} type={"date"} inputRestriction={limitFilterFields}
                          input={{ placeholder: "", max: '9999-12-31' }} component={FilterTextInput} />
                        <Field name="date_end" label={{ for: "date_end", text: "End:" }} type={"date"} inputRestriction={limitFilterFields}
                          input={{ placeholder: "", max: '9999-12-31' }} component={FilterTextInput} />
                        <Field name="selectedAssets" label={{ for: "selectedAssets", text: "Assets:", hideCount: false }}
                          input={{
                            options: assetList,
                            multiLineLabel: true,
                            alteredInput: assetAlteredInputList,
                            alteredInputSearch: assetAlteredInputSearch,
                            customValueRenderer: assetAlterInputSelectedLabel,
                          }} multiple component={MultiSelectInput} />
                        <div className='w-full mb-3'>
                          <span className="block mb-2 text-sm font-medium text-gray-900 dark:text-white w-full">&nbsp;</span>
                          <button type="submit"
                            className={"inline-flex items-center text-white bg-blue-700 hover:bg-blue-800" +
                              " focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-md px-5 py-2.5" +
                              " h-12 mt-auto w-full dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"}>
                            <span>Search</span>
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              {(reports && reports.standards && Object.keys(reports.standards).length > 0
                && reports.standards[Object.keys(reports.standards)[0]].id > 0) ?
                (<div className='mt-4 w-full mb-[15vh] border-t border-black pt-2'>
                  <div className='w-full grid grid-cols-1 md:grid-cols-3 gap-2 gap-y-0 mb-2'>
                    <div className='col-span-2 p-4 flex w-full align-middle'>
                      <div className='w-full mt-auto h-fit text-lg text-center md:text-left'
                      >Showing reports from <span className='font-semibold'
                      >{reports.date_start}</span> - <span className='font-semibold'
                      >{reports.date_end}</span>.</div>
                    </div>
                    <div className='flex w-full justify-center md:justify-end p-4 align-middle'>
                      <button className={"inline-flex items-center text-white bg-gray-700 hover:bg-gray-800" +
                        " focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-md px-5 py-2.5" +
                        " w-full md:w-auto" +
                        " h-12 mt-auto dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"}
                        type="button"
                        onClick={() => { getXlsxFromServer('all') }} onAuxClick={() => { getXlsxFromServer('all') }}
                      ><span className='w-full text-center'>Export all to Excel</span></button>
                    </div>
                  </div>
                  <div className='w-full max-w-full'>
                    {reports.standards.map(function (standard, index) {
                      //console.log('standard', standard)
                      const colspanOfInfo = (Object.keys(standard.inputs).length > 4) ? 3 : 2;
                      return (
                        <div key={standard.id ?? ('-' + index)} className="my-3 p-1 pl-0">
                          <div className="border rounded">
                            <div className="w-full">
                              <div className='w-full grid grid-cols-1 md:grid-cols-3 gap-2 gap-y-0 mb-2'>
                                <div className='p-2 flex w-full align-middle col-span-2'>
                                  <div className='w-full h-fit mt-auto text-lg lg:text-xl text-center md:text-left'>
                                    <span><span className='font-bold'>{standard.part_name}</span> ({
                                      standard.part_number}) {standard.resin} (Rev:&nbsp;{standard.rev})</span>
                                  </div>
                                </div>
                                <div className='flex w-full justify-center md:justify-end pl-4 pb-2 pt-4 pr-4 align-middle'>
                                  <button className={"inline-flex items-center text-white bg-gray-700 hover:bg-gray-800" +
                                    " focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-md px-5 py-2.5" +
                                    " w-full md:w-auto" +
                                    " h-12 mt-auto dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"}
                                    type="button"
                                    onClick={() => { getXlsxFromServer((standard.id ?? '') + '') }}
                                    onAuxClick={() => { getXlsxFromServer((standard.id ?? '') + '') }}
                                  ><span className='w-full text-center'>Export to Excel</span></button>
                                </div>
                              </div>
                            </div>
                            <div className="bte-reports-table-section">
                              <div className='w-full max-w-full grid grid-cols-1 gap-0'>
                                <div className='flex'>
                                  <table className='bte-reports-table'>
                                    <thead>
                                      <tr>
                                        <th className='angled-header'>
                                          <div>
                                            <span className='font-semibold'>Information</span>
                                          </div>
                                        </th>
                                        <th colSpan={(colspanOfInfo - 1)} className='angled-header'>
                                          <div>
                                            <span className='font-normal'></span>
                                          </div>
                                        </th>
                                        {Object.keys(standard.inputs).map(function (inputKey) {
                                          const input = standard.inputs[inputKey];
                                          return (
                                            <React.Fragment key={inputKey}>
                                              <th className='angled-header'>
                                                <div>
                                                  <span className='font-semibold'>{input.name ?? ''}</span>
                                                </div>
                                              </th>
                                              <th className='angled-header'>
                                                <div>
                                                  <span className='font-normal'>{input.placeholder ?? ''}</span>
                                                </div>
                                              </th>
                                            </React.Fragment>
                                          )
                                        })}
                                      </tr>
                                      <tr>
                                        <th colSpan={colspanOfInfo}>{/*information tab*/}</th>
                                        {Object.keys(standard.inputs).map(function (inputKey) {
                                          const input = standard.inputs[inputKey];
                                          return (
                                            <th colSpan={2} key={inputKey} className='text-sm'>
                                              <div>
                                                {input.subtitle ?? ''}
                                              </div>
                                            </th>
                                          )
                                        })}
                                      </tr>
                                    </thead>
                                    <tbody className='bg-white'>
                                      {Object.keys(standard.logs).length < 1 ?
                                        (
                                          <tr>
                                            <td colSpan={(Object.keys(standard.inputs).length * 2) + colspanOfInfo}>
                                              <div>No logs present during this timespan.</div>
                                            </td>
                                          </tr>
                                        ) :
                                        (<>{Object.keys(standard.logs).map(function (logKey) {
                                          const current_log = standard.logs[logKey];
                                          return (
                                            <tr key={logKey}>
                                              <td colSpan={colspanOfInfo}>
                                                <div>
                                                  <span>
                                                    {current_log.info.isValid ? 'Valid' : 'Invalid'} log, submitted
                                                    <span className='font-semibold'> {current_log.info.created_date}</span> by
                                                    <span className='font-semibold'> {current_log.info.creator}</span>.
                                                  </span>
                                                </div>
                                              </td>
                                              {Object.keys(standard.inputs).map(function (standardInputKey) {
                                                const current_log_input = current_log.inputs[standardInputKey];
                                                if (current_log_input) {
                                                  return (<td colSpan={2} key={standardInputKey}>
                                                    <div>{current_log_input.value}</div>
                                                  </td>);
                                                } else {
                                                  return (<td colSpan={2} key={standardInputKey}><div></div></td>);
                                                }
                                              })}
                                            </tr>
                                          )
                                        })
                                        }</>)
                                      }
                                    </tbody>

                                  </table>
                                  <div className='w-[253px] min-w-[253px] h-[250px] bg-[lightgray]'>
                                  </div>
                                </div>
                              </div>


                              {/*TODO: this*/}
                              {/* standards: [{ id: 0, part_name: '', part_number: '', resin: '', rev: '', logs: [], inputs: [] }], */}



                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>)
                :
                (//No reports loaded
                  <div className='mt-4'>
                  </div>
                )
              }
            </div>) :
            ({
              ...failedAL ?
                (
                  <div className='w-full bg-red-600 text-white rounded p-2'>
                    Asset list has failed to load. Please try again shortly.
                  </div>
                ) : (
                  <div>
                    Loading...
                  </div>
                )
            })
          } {/*There is an asset list*/}


          {false && (<div className={true ? "" : "relative overflow-x-auto shadow-md sm:rounded-lg"}>
            <Link to="/reports/logDates" className='inline-flex items-center text-white bg-blue-700 font-semibold hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 rounded-lg text-md px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'>
              Log Dates
            </Link>
            <Link to="/reports/assets" className='inline-flex items-center text-white bg-blue-700 font-semibold hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 rounded-lg text-md px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'>
              Assets
            </Link>
            {/* <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Part Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Part Number
                  </th>
                  <th scope="col" className="px-6 py-3">
                    RESIN
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Rev
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Created at
                  </th>
                  <th scope="col" className="px-6 py-3">
                    <span className="sr-only">Details</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {standards && standards.length > 0 ?
                  standards.map((standard, index) => {

                    return (
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={"standard-" + index}>
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {standard.part_name}
                        </th>
                        <td className="px-6 py-4">
                          {standard.part_number}
                        </td>
                        <td className="px-6 py-4">
                          {standard.resin}
                        </td>
                        <td className="px-6 py-4">
                          {standard.rev}
                        </td>
                        <td className="px-6 py-4">
                          {standard.created_at}
                        </td>
                        <td className="px-6 py-4 text-right">
                          <Link to={standard.id + ""}>
                            <span className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Details</span>
                          </Link>
                        </td>
                      </tr>
                    )
                  })
                  : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" colSpan={5}>No records found.</td>
                  </tr>
                }
              </tbody>
            </table> */}
          </div>)}
        </div>
      </div>
    </MainLayout >
  )
}

const breadcrumbLinks = [
  {
    href: "/reports",
    label: "Reports"
  }
]
