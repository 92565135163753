import React from 'react'
import { assets } from '../../general/assets'
import { useSelector } from 'react-redux'
import { FaUserCircle } from 'react-icons/fa'

export default function UserMenu({ showHeaderDropDown, setShowHeaderDropDown }) {
    const currentUser = useSelector(state => state.authentication?.user)
    console.log(currentUser)
    return (
        <div>
            <button
                onClick={() => {
                    const status = showHeaderDropDown == "user-menu" ? "" : "user-menu"
                    setShowHeaderDropDown(status)
                }}
                className="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" type="button">
                <span className="sr-only">Open user menu</span>
                {/* <a href="#"><img src={assets.profileImagePlaceholder} className='w-10 h-10 rounded-full border-gray-300 border' /></a> */}
                <a href="#"><FaUserCircle className='w-10 h-10 rounded-full border-gray-300 border bg-white' /></a>
            </button>
            {showHeaderDropDown == "user-menu" &&
                <div className="absolute w-[250px] right-0 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600">
                    <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
                        <h5 className='font-bold'>{currentUser ? currentUser.name : ""}</h5>
                        <div className="font-medium truncate">{currentUser ? currentUser.email : ""}</div>
                    </div>
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownUserAvatarButton">
                        <li>
                            <a href="/dashboard" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
                        </li>
                        <li>
                            <a href="/profile" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Profile</a>
                        </li>
                    </ul>
                    <div className="py-2">
                        <a href="/logout" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Sign out</a>
                    </div>
                </div>
            }
        </div>
    )
}
