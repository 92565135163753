import { createSlice } from '@reduxjs/toolkit'

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    user: {},
    isLoggedIn:false
  },
  reducers: {
    logIn: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload?.user
    }, 
    logOut: (state) => {
      state.user = {}
      state.isLoggedIn = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const { logIn, logOut } = authenticationSlice.actions

export default authenticationSlice.reducer
