import React, { useState } from 'react'
import Modal from '../../../components/common/modal'
import { Field, Form, Formik } from 'formik'
import TextInput from '../../../components/form/textInput'
import TextAreaInput from '../../../components/form/textAreaInput'
import Errors from '../../../components/common/errors'
import FileInput from '../../../components/form/fileInput'
import { PostRequestWithFiles } from '../../../services/apiService'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toggleLoading } from '../../../slices/loadingSlice'
import apiEndPoints from '../../../services/apiEndPoints'

export default function EditGuideline({ standardId, guidelines, showEditForm, setShowEditForm, setGuideline }) {
    const [errors, setErrors] = useState([])

    const dispatch = useDispatch()
    const navigate = useNavigate()

    //
    const setLoading = (show = true) => {
        dispatch(toggleLoading(show))
    }

    const submitInspectionGuideline = async (data) => {
        setLoading(true)
        const formData = new FormData();
        formData.append("guidelines", data.guidelines)
        formData.append("image", data.image)
        formData.append("inspection_standard_id", standardId)
        //
        const resp = await PostRequestWithFiles(apiEndPoints.inspectionStandards.updateGuidlines, { data: formData })
        setLoading(false)
        if (resp.data.status == "success") {
            alert("Standard inspection guidlines updated succcessfully.")
            setShowEditForm(false)
            setGuideline(resp.data.data);
        } else {
            setErrors(resp.data.errors)
        }
    }

    return (
        <div>
            <Modal modalData={{ id: "addInput", title: "Inspection Guidelines" }} setShowModal={setShowEditForm} showModal={showEditForm}>
                <div className="p-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <Errors errors={errors} />
                    <Formik
                        initialValues={{
                            guidelines: guidelines ?? "",
                            image: ""
                        }}
                        //validationSchema={validationSchema}
                        onSubmit={(data) => {
                            submitInspectionGuideline(data)
                        }}
                    >
                        {(props) => (
                            <Form>
                                <div className='block'>
                                    <div className='w-full p-2'>
                                        <Field name="guidelines" label={{ for: "guidelines", text: "Inspection Guidelines" }} input={{ placeholder: "Enter inspection guidelines" }} component={TextAreaInput} />
                                    </div>
                                </div>
                                <div className='block'>
                                    <div className='w-full p-2'>
                                        <Field name="image" label={{ for: "image", text: "Image" }} input={{ placeholder: "Choose master image", type: "file" }} component={FileInput} />
                                    </div>
                                </div>
                                <div className='mt-5 px-3'>
                                    <button type="submit" className="inline-flex items-center text-white bg-blue-700 font-semibold hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 rounded-lg text-md px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                        <span className='text-lg font-semibold'>Submit</span>
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </div>
    )
}
