import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleLoading } from '../../slices/loadingSlice';
import apiEndPoints from '../../services/apiEndPoints';
import { GetRequest, PostRequest } from '../../services/apiService';
import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup';
import MultiSelectInput from '../form/multiSelectInput';
import DashboardPieChart from './chartComponents/pieChart';
import DashboardLineChart from './chartComponents/lineChart';
import FilterTextInput from './filterTextInput';
import DashboardStackedBarChart from './chartComponents/stackedBarChart';


export default function DashboardChart({ }) {
    const [assetList, setAssetList] = useState([]);
    const [timespanLogList, setTimespanLogList] = useState({
        standards: [{ id: 0, name: '', logs: [] }],
        logcount: 0, badlogcount: 0,
        //    date_start: new Date(2023, 8, 1).toISOString().split('T')[0], /*    TODO: REMOVE THIS, THIS IS FOR TESTING. use the real one, below     */
        //    date_end: new Date(2023, 9, 12).toISOString().split('T')[0] /*    TODO: REMOVE THIS, THIS IS FOR TESTING. use the real one, below    */
        date_start: new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1).toISOString().split('T')[0],
        date_end: new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0).toISOString().split('T')[0]
    });
    const [errors, setErrors] = useState({});
    const [statsLoading, setStatsLoading] = useState(true);

    const dispatch = useDispatch();
    const setLoading = (show = true) => {
        dispatch(toggleLoading(show))
    }



    //functionality for asset list searchbar start
    const assetAlteredInputList = [
        { replace: { old: 'Part Name: "', new: "" } },
        { replace: { old: '",' + "\n" + 'Part Number: "', new: "|||" } },
        { replace: { old: '",' + "\n" + 'Resin: "', new: "|||" } },
        { replace: { old: '",' + "\n" + 'Rev: "', new: "|||" } },
        { trimEnd: 1 }
    ]
    const assetAlteredInputSearch = {
        type: 'split',
        splitChars: '|||',
        filterInputFields:
        {
            'name': 0,
            'part name': 0,
            'number': 1,
            'part number': 1,
            'resin': 2,
            'rev': 3,
        }
    }
    const assetAlterInputSelectedLabel = ((label) => {
        let newLabel = (label ?? '').trim();
        if (!(newLabel.includes('Part Name: "') && newLabel.includes('",' + "\n" + 'Part Number: "'))) return label;

        newLabel = newLabel.split('Part Name: "');
        if (newLabel.length < 2) return label;
        newLabel = newLabel[1].split('",' + "\n" + 'Part Number: "')[0];

        return newLabel;
    })
    //functionality for asset list searchbar end


    async function getAssetList(reload = false) {
        //setLoading(true);
        let url = apiEndPoints.dashboard.getAssets;
        if (reload) url = url + '?first_time';
        const resp = await GetRequest(url)
        if (resp && resp.data && resp.data.status == "success") {
            console.log('resp assetlist ', resp.data.data);
            setAssetList(resp.data.data.options);
        }
        //setLoading(false);
    }


    const validationSchema = Yup.object().shape({
        date_start: Yup.date().required('Required'),
        date_end: Yup.date().required('Required'),
        selectedAssets: Yup.array().required('Required').min(1, 'Please select at least 1 asset').max(10, 'Too many assets, please select 10 or fewer assets!')
    });

    const processFilterChange = async (values) => {
        //console.log('submit');
        //setLoading(true);
        setStatsLoading(true);
        setErrors({});
        const resp = await PostRequest(apiEndPoints.dashboard.getChartData, { data: values });
        //console.log('filter response: ', resp)
        if (resp.data && resp.data.status) {
            if (resp.data.errors) {
                console.warn('Error when loading data: ', resp.data.errors)
            }
            if (resp.data.status === 'success') {
                setTimespanLogList({ ...resp.data.data });

                console.log('success', resp.data.data);
            }
        }
        //setStatsLoading(false);//don't do it here, useEffect handles if timespanLL updates
        //setLoading(false)
    }

    useEffect(() => {
        if (timespanLogList.standards.length < 1 || timespanLogList.standards[0].id === 0) return;
        setStatsLoading(false);
    }, [timespanLogList]);


    //filter fields updating values when going outside date range
    const maxFilterDayGap = 42;
    let filterFieldsInProgress = false;
    //trigger 1ms timeframe so they don't yo-yo
    function resetFilterFieldsInProgress() { setTimeout(() => { filterFieldsInProgress = false; }, 1); }
    function limitFilterFields(field_name, field_original_value, form_date_start, form_date_end, changingFunction) {
        if (filterFieldsInProgress && filterFieldsInProgress !== field_name) return;
        if ((field_name === 'date_start' && field_original_value === form_date_start) ||
            (field_name === 'date_end' && field_original_value === form_date_end)) return;
        filterFieldsInProgress = field_name;

        let partialDate = form_date_start.split('-');
        const start_time = new Date(partialDate[0], partialDate[1] - 1, partialDate[2]);
        partialDate = form_date_end.split('-');
        const end_time = new Date(partialDate[0], partialDate[1] - 1, partialDate[2]);

        switch (field_name) {
            default:
                filterFieldsInProgress = false;
                return;
            case 'date_start':
                const maxGapStartDate = new Date(start_time.getFullYear(), start_time.getMonth(), start_time.getDate() - 1 + maxFilterDayGap);

                if (end_time.getTime() > maxGapStartDate.getTime() || end_time.getTime() <= start_time.getTime()) {
                    changingFunction('date_end', maxGapStartDate.toISOString().slice(0, 10));
                    console.log('ess', start_time.toISOString().slice(0, 10), maxGapStartDate.toISOString().slice(0, 10));

                    resetFilterFieldsInProgress();
                    return;
                }
                filterFieldsInProgress = false;
                return;
            case 'date_end':
                const maxGapEndDate = new Date(end_time.getFullYear(), end_time.getMonth(), end_time.getDate() + 1 - maxFilterDayGap);

                if (start_time.getTime() < maxGapEndDate.getTime() || start_time.getTime() >= end_time.getTime()) {
                    changingFunction('date_start', maxGapEndDate.toISOString().slice(0, 10));
                    console.log('eds', end_time.toISOString().slice(0, 10), maxGapEndDate.toISOString().slice(0, 10))

                    resetFilterFieldsInProgress();
                    return;
                }

                filterFieldsInProgress = false;
                return;
        }
    }


    const setupInitialFilterData = async () => {
        let assetSelected = [];
        Object.keys(assetList).forEach((key) => {
            const asset = assetList[key];
            if (asset.selected) assetSelected.push(asset.value);
        })

        processFilterChange({
            date_start: timespanLogList.date_start,
            date_end: timespanLogList.date_end,
            selectedAssets: assetSelected
        });
    }

    useEffect(() => {
        if (assetList.length < 1) return;

        setupInitialFilterData();
    }, [assetList]);


    useEffect(() => {
        getAssetList(true);
    }, []);

    return (
        (assetList.length > 0) ? (
            <div className="w-full">
                <Formik //filters
                    validationSchema={validationSchema}
                    initialValues={{ date_start: timespanLogList.date_start, date_end: timespanLogList.date_end, selectedAssets: [] }}
                    onSubmit={(values) => {
                        if (statsLoading) return false;
                        processFilterChange(values)
                    }}
                >
                    {(props) => (
                        <Form>
                            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 gap-y-0 mb-2'>
                                <Field name="date_start" label={{ for: "date_start", text: "Start:" }} type={"date"} inputRestriction={limitFilterFields}
                                    input={{ placeholder: "", max: '9999-12-31' }} component={FilterTextInput} />
                                <Field name="date_end" label={{ for: "date_end", text: "End:" }} type={"date"} inputRestriction={limitFilterFields}
                                    input={{ placeholder: "", max: '9999-12-31' }} component={FilterTextInput} />
                                <Field name="selectedAssets" label={{ for: "selectedAssets", text: "Assets:", hideCount: false }}
                                    input={{
                                        options: assetList,
                                        multiLineLabel: true,
                                        alteredInput: assetAlteredInputList,
                                        alteredInputSearch: assetAlteredInputSearch,
                                        customValueRenderer: assetAlterInputSelectedLabel,
                                    }} multiple component={MultiSelectInput} />
                                <div className='w-full mb-3'>
                                    <span className="block mb-2 text-sm font-medium text-gray-900 dark:text-white w-full">&nbsp;</span>
                                    <button type="submit"
                                        className={"inline-flex items-center text-white bg-blue-700 hover:bg-blue-800" +
                                            " focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-md px-5 py-2.5" +
                                            " h-12 mt-auto w-full dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"}>
                                        <span>Search</span>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                {statsLoading && (<div className="w-full">Loading Charts...</div>)}
                {!statsLoading && timespanLogList.logcount === 0 && (<div className="w-full text-xl">No logs present during this timeframe.</div>)}
                {!statsLoading && timespanLogList.logcount !== 0 && (<div className="w-full">
                    <div className='w-full grid gap-2 grid-cols-1 lg:grid-cols-3'>
                        <div className='w-full h-fit max-h-[500px] p-2 border flex justify-center overflow-x-scroll md:overflow-x-none lg:scrollbar-hide'>
                            <div className='w-full h-full max-w-[450px]'>
                                <div className='w-full h-full flex justify-center min-w-[250px] pb-4'>
                                    <DashboardPieChart
                                        logcounts={[timespanLogList.badlogcount, timespanLogList.logcount]}
                                        date_range={{ date_start: timespanLogList.date_start, date_end: timespanLogList.date_end }} />
                                </div>
                            </div>
                        </div>
                        <div className='w-full h-fit max-h-[500px] p-2 border flex justify-center lg:col-span-2 overflow-x-scroll md:overflow-x-none lg:scrollbar-hide'>
                            <div className='w-full h-full max-w-[1500px] md:max-w-[900px]'>
                                <div className='w-full h-full flex justify-center min-w-[500px] pb-4'>
                                    <DashboardLineChart
                                        standards={timespanLogList.standards}
                                        date_range={{ date_start: timespanLogList.date_start, date_end: timespanLogList.date_end }} />
                                </div>
                            </div>
                        </div>
                        <div className='w-full h-fit p-2 border flex justify-center lg:col-span-3 overflow-x-scroll md:overflow-x-none lg:scrollbar-hide'>
                            <div className='w-full h-full max-w-full'>
                                <div className='w-full h-full flex justify-center min-w-[500px] pb-4'>
                                    <DashboardStackedBarChart
                                        standards={timespanLogList.standards}
                                        date_range={{ date_start: timespanLogList.date_start, date_end: timespanLogList.date_end }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            </div >
        ) : <div className="w-full">Loading Assets...</div>);
}