import { useDispatch, useSelector } from "react-redux"
import { toggleLoading } from "../../slices/loadingSlice"
import { useEffect } from "react"
import { logOut } from "../../slices/authenticationSlice"
import { Navigate, useLocation } from "react-router-dom"

export default function Logout() {
    const dispatch = useDispatch()
    const loading = useSelector(state => state.loading)
    let location = useLocation();

    const setLoading = (show = true) => {
        dispatch(toggleLoading(show))
    }

    useEffect(() => {
        setLoading(true)
        localStorage.setItem('AppData', JSON.stringify({ user: {}, isLoggedIn: false }))
        dispatch(logOut())
        setLoading(false)
    }, [])

    return (
        <Navigate to="/" state={{ from: location }} replace={false} />
    )

}