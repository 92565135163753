import axios from "axios";

//const ApiBaseURL = "http://apibteisma.dev2.wilmottech.com/";
//const ApiURL = "http://apibteisma.dev2.wilmottech.com/api/v1/";

// const ApiBaseURL = "http://bte-demo.azdev01.wilmottech.com/"; //AZURE DEMO
// const ApiURL = "http://bte-demo.azdev01.wilmottech.com/api/v1/"; //AZURE DEMO
const ApiBaseURL = "https://bte-demo.azdev01.wilmottech.com/"; //AZURE DEMO
const ApiURL = "https://bte-demo.azdev01.wilmottech.com/api/v1/"; //AZURE DEMO

// const ApiBaseURL = "http://isma.bte.local/"; //LOCAL
// const ApiURL = "http://isma.bte.local/api/v1/"; //LOCAL

//
//
const api = axios.create({
    baseURL: ApiURL,
    headers: {
        Accept: "application/json"
    },
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log(error);
        if (error?.response?.status === 401) {
            //deleteUserData();
        }
        if (error?.response?.status === 400) {
            return error.response;
        }
        if (error?.response?.status === 429) {
            alert("You have loaded data faster than the server can handle!\nPlease wait a moment before trying again.");
        }
        return error.response;
    }
);

const customConfig = {
    headers: {
        // "Access-Control-Allow-Origin": "http://apibteisma.dev2.wilmottech.com", // this will allow all CORS requests
        "Access-Control-Allow-Methods": "OPTIONS,POST,GET", // this states the allowed methods
        "Access-Control-Allow-Headers": "*", // this will allow all CORS requests
        "Content-Type": "multipart/form-data", // this shows the expected content type
    },
};

const customConfigWithFiles = {
    headers: {
        // "Access-Control-Allow-Origin": "http://apibteisma.dev2.wilmottech.com", // this will allow all CORS requests
        "Access-Control-Allow-Methods": "OPTIONS,POST,PUT,GET", // this states the allowed methods
        "Access-Control-Allow-Headers": "*", // this will allow all CORS requests
        "Content-Type": "multipart/form-data", // this shows the expected content type
    },
};

//
async function GetRequest(endpoint, params) {
    const requestAPI = ApiURL + endpoint;
    //
    const userToken = await getAuthToken();
    if (userToken) {
        customConfig.headers.Authorization = "Bearer " + userToken;
    }

    //
    customConfig.params = params;

    //
    try {
        const response = await api.get(requestAPI, customConfig);
        return response;
    } catch (error) {
        console.error(error);
    }
}

//
async function PostRequestWithFiles(endpoint, params) {
    const requestAPI = ApiURL + endpoint;

    //
    const userToken = await getAuthToken();
    if (userToken) {
        customConfigWithFiles.headers.Authorization = "Bearer " + userToken;
    }

    //
    try {
        const formdata = params.data;
        const response = await api
            .post(requestAPI, formdata, customConfigWithFiles)
            .catch((error) => {
                if (error.response && error.response.data.status) {
                    return error.response?.data;
                }
            });

        //
        return response;
    } catch (error) {
        console.error(error);
    }
}

//
async function PostRequest(endpoint, params) {
    const requestAPI = ApiURL + endpoint;
    //
    const userToken = await getAuthToken();
    if (userToken) {
        customConfig.headers.Authorization = "Bearer " + userToken;
    }
    //
    try {
        const formdata = params.data;
        const response = await api
            .post(requestAPI, formdata, customConfig)
            .catch((error) => {
                console.log(error);
                if (error.response) {
                    if (error.response?.errors) {
                        return error.response.errors;
                    } else {
                        return error.response?.data;
                    }
                }
            });

        console.log(response);
        return response;
    } catch (error) {
        console.error(error);
    }
}

//
async function PutRequest(endpoint, params) {
    const requestAPI = ApiURL + endpoint;
    //
    const userToken = await getAuthToken();
    if (userToken) {
        customConfigWithFiles.headers.Authorization = "Bearer " + userToken;
    }

    //
    try {
        const formdata = params.data;
        const response = await api
            .put(requestAPI, formdata, customConfig)
            .catch((error) => {
                if (error.response && error.response.data.status) {
                    return error.response?.data;
                }
            });

        //
        return response;
    } catch (error) {
        console.error(error);
    }
}

//
async function PatchRequest(endpoint, params) {
    const requestAPI = endpoint + "/?" + params.data;
    //
    const userToken = await getAuthToken();
    if (userToken) {
        customConfigWithFiles.headers.Authorization = "Bearer " + userToken;
    }

    //
    try {
        const response = await api.get(requestAPI, customConfig).catch((error) => {
            if (error.response && error.response.data.status) {
                return error.response?.data;
            }
        });
        //
        return response;
    } catch (error) {
        console.error(error);
    }
}

//
async function PutRequestWithFiles(endpoint, params) {
    const requestAPI = ApiURL + endpoint;
    //
    const userToken = await getAuthToken();
    if (userToken) {
        customConfigWithFiles.headers.Authorization = "Bearer " + userToken;
    }
    try {
        const formdata = params.data;
        const response = await api
            .put(requestAPI, formdata, customConfigWithFiles)
            .catch((error) => {
                if (error.response && error.response.data.status === 400) {
                    return error.response?.data;
                }
            });

        //
        return response;
    } catch (error) {
        console.error(error);
    }
}

//
async function DeleteRequest(endpoint) {
    const requestAPI = endpoint;
    //
    const userToken = await getAuthToken();
    if (userToken) {
        customConfig.headers.Authorization = "Bearer " + userToken;
    }

    try {
        const response = await api.delete(requestAPI, customConfig);
        return response;
    } catch (error) {
        console.error(error);
    }
}

//
const getAuthToken = () => {
    const getuserData = localStorage.getItem("AppData");
    console.log("userdata", getuserData);
    if (getuserData) {
        const userData = JSON.parse(getuserData);
        const userToken = userData.user.token;
        return userToken;
    }
    return null;
};

export {
    ApiBaseURL,
    GetRequest,
    PostRequest,
    PutRequest,
    PostRequestWithFiles,
    PutRequestWithFiles,
    PatchRequest,
    DeleteRequest,
};