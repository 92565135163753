import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import MainLayout from '../../layouts/mainLayout'
import Breadcrumb from '../../components/layout/breadcrumb'
import PageTitle from '../../components/paritals/pageTitle'
import PageHeader from '../../components/paritals/pageHeader'
import HeaderActionButton from '../../components/paritals/headerActionButton'
import { Field, Formik, Form } from 'formik'
import TextInput from '../../components/form/textInput'
import FileInput from '../../components/form/fileInput'
import SelectInput from '../../components/form/selectInput'
import TextAreaInput from '../../components/form/textAreaInput'
import * as Yup from 'yup';
import { PostRequestWithFiles } from '../../services/apiService'
import apiEndPoints from '../../services/apiEndPoints'
import Errors from '../../components/common/errors'
import { useDispatch } from 'react-redux';
import { toggleLoading } from '../../slices/loadingSlice';

export default function AddStandard() {
  const dispatch = useDispatch()

  //
  const setLoading = (show = true) => {
    dispatch(toggleLoading(show))
  }

  //
  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState("")

  //
  const navigate = useNavigate();

  const submitStandard = async (data) => {
    setLoading(true)
    const formData = new FormData();
    formData.append("part_name", data.partName)
    formData.append("part_number", data.partNumber)
    formData.append("resin", data.resin)
    formData.append("rev", data.rev)
    formData.append("master_image", data.masterImage)
    formData.append("details", data.details)
    formData.append("status", data.status)

    //
    const resp = await PostRequestWithFiles(apiEndPoints.inspectionStandards.add, { data: formData })
    setLoading(false)
    if (resp.data.status == "success") {
      alert("Standard added succcessfully.")
      navigate("/standards/" + resp.data.data.id)
    } else {
      setErrors(resp.data.errors)
    }
  }

  return (
    <MainLayout>
      <div className='p-3'>
        <Breadcrumb links={breadcrumbLinks} />
        <div>
          <PageHeader>
            <PageTitle title="Add New Standard" />
            <HeaderActionButton />
          </PageHeader>
          <div className="bg-white border border-gray-200 relative overflow-x-auto shadow-md sm:rounded-lg">
            <div className="p-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <Errors errors={errors} />
              <Formik
                initialValues={{
                  partName: '',
                  partNumber: '',
                  resin: '',
                  rev: '',
                  masterImage: '',
                  status: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(data, actions) => {
                  submitStandard(data)
                }}
              >
                {(props) => (
                  <Form>
                    <div className='block sm:flex'>
                      <div className='w-full sm:w-1/2 md:w-1/4 p-2'>
                        <Field name="partName" label={{ for: "partName", text: "Part Name" }} input={{ placeholder: "Enter part name" }} component={TextInput} />
                      </div>
                      <div className='w-full sm:w-1/2 md:w-1/4 p-2'>
                        <Field name="partNumber" label={{ for: "partNumber", text: "Part Number" }} input={{ placeholder: "Enter part number" }} component={TextInput} />
                      </div>
                      <div className='w-full sm:w-1/2 md:w-1/4 p-2'>
                        <Field name="resin" label={{ for: "resin", text: "RESIN" }} input={{ placeholder: "Enter resin" }} component={TextInput} />
                      </div>
                      <div className='w-full sm:w-1/2 md:w-1/4 p-2'>
                        <Field name="rev" label={{ for: "rev", text: "Rev" }} input={{ placeholder: "Enter rev" }} component={TextInput} />
                      </div>
                    </div>
                    <div className='block'>
                      <div className='w-full p-2'>
                        <Field name="details" label={{ for: "details", text: "Details" }} input={{ placeholder: "Enter details" }} component={TextAreaInput} />
                      </div>
                    </div>
                    <div className='block sm:flex'>
                      <div className='w-full sm:w-1/2 md:w-1/4 p-2'>
                        <Field name="masterImage" label={{ for: "masterImage", text: "Master Image" }} input={{ placeholder: "Choose master image", type: "file" }} component={FileInput} />
                      </div>
                      <div className='w-full sm:w-1/2 md:w-1/4 p-2'>
                        <Field name="status" label={{ for: "status", text: "Status" }} input={{ placeholder: "Choose Status", options: [{ label: "Active", value: "active" }, { label: "Inactive", value: "inactive" }] }} component={SelectInput} />
                      </div>
                    </div>
                    <div className='mt-5 mb-16 px-3'>
                      <button type="submit" className="inline-flex items-center text-white bg-blue-700 font-semibold hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 rounded-lg text-md px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        <span className='text-lg font-semibold'>Submit</span>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

const validationSchema = Yup.object().shape({
  partName: Yup.string().required('Required'),
  partNumber: Yup.string().required('Required'),
  resin: Yup.string().required('Required'),
});

const breadcrumbLinks = [
  {
    href: "/standards",
    label: "Standards"
  },
  {
    href: "/standards/add",
    label: "Add"
  }
]
