import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/mainLayout';
import Breadcrumb from '../../components/layout/breadcrumb';
import PageTitle from '../../components/paritals/pageTitle';
import PageHeader from '../../components/paritals/pageHeader';
import HeaderActionButton from '../../components/paritals/headerActionButton';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggleLoading } from '../../slices/loadingSlice';
import apiEndPoints from '../../services/apiEndPoints';
import { GetRequest, PostRequest } from '../../services/apiService';
import { Link } from 'react-router-dom';
import { FaCircle } from 'react-icons/fa';


export default function ShowNotification() {
    const [reloadHead, setReloadHead] = useState(0);
    const [notification, setNotification] = useState({});

    let { id } = useParams();

    const dispatch = useDispatch();

    const setLoading = (show = true) => {
        dispatch(toggleLoading(show))
    }

    const loadInitDetails = async () => {
        const padding = false;
        setLoading(true);
        const resp = await GetRequest(apiEndPoints.notification.index + "/" + id)
        setLoading(false);

        if (resp && resp.data && resp.data.status == "success") {
            console.log('Notification data: ', resp.data.data)
            setNotification(resp.data.data)
            if (resp.data.data.id) {
                const postResp = await PostRequest(apiEndPoints.notification.read + "/" + id, [])
                if (postResp && postResp.data && postResp.data.status == "success") {
                    setReloadHead(1);
                    console.log("Read update response '", postResp.data.data, "'");
                }
            }
        }
    }

    useEffect(() => {
        loadInitDetails();
    }, [])

    useEffect(() => {
        console.log("notification: ", notification);
    }, [notification])


    return (
        <MainLayout reloader={reloadHead}>
            <div className='p-3'>
                <Breadcrumb links={breadcrumbLinks} />
                <div>
                    <PageHeader>
                        <PageTitle title="Notification Details" />
                    </PageHeader>

                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table className='w-full'>
                            <tbody>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <td className="px-4 py-2 font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                                        {(!!notification.isread) ? <FaCircle color="green" className='mx-auto' title={notification.readat} /> : <FaCircle color="red" className='mx-auto' />}
                                    </td>
                                    <td className="px-6 py-2 w-full md:max-w-[80%]">
                                        {notification.message ?? "Loading..."}
                                    </td>
                                    <td className="px-6 py-2 w-full">
                                        {notification.created_at &&
                                            (<>{notification.created_at} <span className='text-xs'>({notification.timegap})</span></>)}
                                    </td>
                                </tr>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <td colSpan="3" className="px-6 py-4">
                                        {notification.details && Object.keys(notification.details).map(function (val) {
                                            const value = notification.details[val];
                                            console.log('Notification value: ', value);

                                            switch (value.type) {
                                                case 'text':
                                                    return (<span key={val} className={'' + (value.classNames ? (value.classNames) : '')}>{value.data}</span>)
                                                case 'break':
                                                    return (<span key={val}><br /></span>)
                                                case 'link':
                                                    return (
                                                        <a key={val} href={"/" + (value.destination ?? 'dashboard')}
                                                            className={"text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white" + (value.classNames ? (' ' + value.classNames) : '')}>
                                                            {value.label ?? 'Link'}</a>
                                                    )
                                                default:
                                                    return (<span key={val} ></span>);
                                            }
                                        })}
                                        {/* {notification.details &&
                                        <div className='w-full' dangerouslySetInnerHTML={{ __html: notification.details }}></div> || <>&nbsp;</>} */}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}

const breadcrumbLinks = [
    {
        href: "/notifications",
        label: "Notifications"
    },
    {
        label: "Show"
    }
]
