import React, { useEffect, useState } from 'react'

export default function FilterTextInput({
  field, // { name, value, onChange, onBlur },
  form,
  form: { touched, errors,
    values: { date_start: formStart, date_end: formEnd },
    setFieldValue: formSetField }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  inputRestriction,
  ...props
}) {

  const [myVal, setMyVal] = useState('');

  function filterDateInputs() {
    //ensure fields are setup
    if (!field || !field.name) return;
    if (!inputRestriction) inputRestriction = (() => { });
    switch (field.name) {
      case 'date_start':
        if (myVal && myVal !== '' && myVal !== formStart) {
          inputRestriction(field.name, myVal, formStart, formEnd, formSetField);
        }
        setMyVal(formStart);
        return;
      case 'date_end':
        if (myVal && myVal !== '' && myVal !== formEnd) {
          inputRestriction(field.name, myVal, formStart, formEnd, formSetField);
        }
        setMyVal(formEnd);
        return;
      default:
        return;
    }
  }

  useEffect(() => {
    filterDateInputs();
  }, [formStart, formEnd]);


  return (
    <div className="w-full mb-3">
      <label htmlFor={props.label.for} className={props.label.classes ?? "block mb-2 text-sm font-medium text-gray-900 dark:text-white"}>{props.label.text}</label>
      {/*onInput={props.onInput ?? (() => { })} //value={myVal} onChange={handleChange}*/}
      <input {...field} id={props.input.id} type={props.type}
        className={props.input.classes ?? " h-12 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"}
        placeholder={props.input.placeholder ?? ""} key={field.name} min={props.input.min} max={props.input.max} />
      {field && field.name && touched[field.name] &&
        errors[field.name] && <div className="text-red-500 text-sm">{errors[field.name]}</div>}
    </div>
  )
}
