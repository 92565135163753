import React, { useEffect, useState } from 'react'
import EditGuideline from './editGuideLine'
import { ApiBaseURL } from '../../../services/apiService'

export default function GuideLineDetails({ standardId, guideline }) {
    const [showEditForm, setShowEditForm] = useState(false)
    const [myGuideline, setMyGuideline] = useState({})

    useEffect(() => {
        setMyGuideline({ ...guideline })
    }, [guideline])

    return (
        <div className='mt-5'>
            <div className='flex'>
                <div className='grow'>
                    <h3 className='my-3 font-semibold text-lg'>Inspection Guidelines</h3>
                </div>
                <div>
                    <button type='button' className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        data-modal-target="addInput" data-modal-toggle="addInput"
                        onClick={() => {
                            console.log("here we are...")
                            setShowEditForm(!showEditForm)
                        }}
                    >
                        {showEditForm ? "Show Guidelines" : "Edit Guidelines"}
                    </button>
                </div>
            </div>
            <div className='bg-white border border-gray-200 relative overflow-x-auto shadow-md sm:rounded-lg p-3'>
                <div className='flex'>
                    <div className='md:w-2/3'>
                        <h3 className="font-semibold text-md mb-2">Details</h3>
                        <p className='whitespace-pre-wrap'>{myGuideline?.guidelines ?? "N/A"}</p>
                    </div>
                    <div className='md:w-1/3'>
                        <h3 className="font-semibold text-md mb-2">Image</h3>
                        {myGuideline?.image ? (<img src={ApiBaseURL + myGuideline.image} className='mx-auto rounded' />) : (<span className="text-sm p-2">No Image</span>)}
                    </div>
                </div>
            </div>
            {
                showEditForm && <EditGuideline standardId={standardId} guidelines={myGuideline?.guidelines} showEditForm={showEditForm} setShowEditForm={setShowEditForm} setGuideline={setMyGuideline} />
            }
        </div>
    )
}
