import React from 'react'
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa'

export default function StandardInputs({ inputs, handleEditInput }) {
    return (
        <div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Type
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Required
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Variable
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Base Value
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Negative Varitation Allowed
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Postive Varitation Allowed
                            </th>
                            <th scope="col" className="px-6 py-3 text-center">
                                Allow Invalid?
                            </th>
                            <th scope="col" className="px-6 py-3">
                                <span className="sr-only">Details</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {inputs && inputs.length > 0 ? inputs.map((input, index) => {
                            return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={"standard-inpt" + input.id}>
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {input.title}
                                </th>
                                <td className="px-6 py-4">
                                    {input.input_type}
                                </td>
                                <td className="px-6 py-4">
                                    {input.required ? "Yes" : "No"}
                                </td>
                                <td className="px-6 py-4">
                                    {input.variable ? "Yes" : "No"}
                                </td>
                                <td className="px-6 py-4">
                                    {input.base_value ?? "-"}
                                </td>
                                <td className="px-6 py-4">
                                    {input.min_variation ?? "-"}
                                </td>
                                <td className="px-6 py-4">
                                    {input.max_variation ?? "-"}
                                </td>
                                <td className="px-6 py-4">
                                    <div className='w-full flex justify-center'>
                                        {(typeof input.allowInvalidData !== 'undefined') ? (input.allowInvalidData ? <FaRegCheckSquare /> : <FaRegSquare />) : <FaRegSquare />}
                                    </div>
                                </td>
                                <td className="px-6 py-4 text-right">
                                    <button className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => { handleEditInput(input.id) }}>Details</button>
                                </td>
                            </tr>
                        }) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <td className="px-6 py-4" colSpan={7}>No inputs found.</td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
