import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { bteFormatDate } from '../../../helpers/dateFormatter';

// Create a custom tooltip positioner to put at the bottom of the chart area
Tooltip.positioners.bottom = function (items) {
    const pos = Tooltip.positioners.average(items);

    // Happens when nothing is found
    if (pos === false) {
        return false;
    }

    const chart = this.chart;

    return {
        x: pos.x,
        y: chart.chartArea.bottom,
        yAlign: 'bottom'
    };
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function DashboardStackedBarChart(
    data,
    ...props
) {
    const [dateRange, setDateRange] = useState({
        date_start: new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1).toISOString().split('T')[0],
        date_end: new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0).toISOString().split('T')[0]
    });
    const [inspectionLogs, setInspectionLogs] = useState([]);
    const [goodLogs, setGoodLogs] = useState([]);
    const [badLogs, setBadLogs] = useState([]);


    useEffect(() => {
        //console.log('barchart data change', data)
        if (data && data.date_range) {
            setDateRange(data.date_range)
        }
        if (data.standards && Array.isArray(data.standards) &&
            data.standards.length > 0 && data.standards[0].id !== 0) {

            let newIL = {};
            let newIL_Good = {};
            let newIL_Bad = {};
            data.standards.forEach(function (element) {
                let standardLogCounts = {
                    good: 0,
                    bad: 0
                };
                element.logs.forEach(function (elem) {
                    standardLogCounts.good += elem.valid ? 1 : 0;
                    standardLogCounts.bad += elem.valid ? 0 : 1;
                })
                newIL[element.id] = {
                    name: element.name,
                    number: element.number,
                    resin: element.resin,
                    rev: element.rev,
                    log_counts: standardLogCounts
                };
                newIL_Good[element.id] = standardLogCounts.good;
                newIL_Bad[element.id] = standardLogCounts.bad;
            });
            for (let i = Object.keys(newIL).length; i < 10; i++) {
                newIL[(-1 * i)] = {
                    name: '',
                    number: '',
                    resin: '',
                    rev: '',
                    log_counts: { good: 0, bad: 0 }
                };
                newIL_Good[(-1 * i)] = 0;
                newIL_Bad[(-1 * i)] = 0;
            }
            setInspectionLogs(newIL);
            setGoodLogs(newIL_Good);
            setBadLogs(newIL_Bad);

        } else {
            setInspectionLogs({});
            setGoodLogs({});
            setBadLogs({});
        }
    }, [data]);

    return (
        <Bar className='max-w-[1150px] max-h-[600px]'
            options={{
                animation: { duration: 0 },
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    y: {
                        beginAtZero: true,
                        offset: true,
                        stacked: true,
                        ticks: {
                            callback: function (label, index, labels) {
                                //Returns last label and all whole numbers
                                if (Math.floor(label) === label || index === labels.length - 1) {
                                    return label;
                                }
                            }
                        },
                    },
                    x: {
                        stacked: true,
                        ticks: {
                            callback: function (label, index, labels) {
                                const labelWidth = (labels.length) ? (((this.width - this.paddingLeft - this.paddingRight) * 1.0) / (labels.length * 1.0)) : (0);
                                if (!labelWidth) return '';

                                if ((labels.length > 5) && this.chart?.height && (this.chart.height < (((labels.length - 5) * 50.0) + 225))) {
                                    //hide label on small screen
                                    return '';
                                }

                                const insp_log_id = this.getLabelForValue(label) ?? '-1';
                                if (Object.keys(inspectionLogs).includes(insp_log_id)) {
                                    const theLog = inspectionLogs[insp_log_id];
                                    if (theLog.name === '') return '';

                                    let newLabelRet = [theLog.name,
                                    theLog.number,
                                    theLog.resin,
                                    'Rev ' + theLog.rev];

                                    if (this.ctx?.measureText) {
                                        const mtthis = this;
                                        newLabelRet.forEach(function (value, index) {
                                            if (mtthis.ctx.measureText(value).width > (labelWidth - 10)) {
                                                newLabelRet[index] = value.substring(0, 10) + '...';
                                            }
                                        });
                                        if (
                                            (this.ctx.measureText(newLabelRet[0]).width > (labelWidth - 10)) ||
                                            (this.ctx.measureText(newLabelRet[1]).width > (labelWidth - 10)) ||
                                            (this.ctx.measureText(newLabelRet[2]).width > (labelWidth - 10)) ||
                                            (this.ctx.measureText(newLabelRet[3]).width > (labelWidth - 10))
                                        ) {
                                            newLabelRet = [];
                                        }
                                    }

                                    return newLabelRet;
                                } else {
                                    return this.getLabelForValue(label);
                                }
                            }
                        },
                    }
                },
                plugins: {
                    title: {
                        display: true,
                        text: function (context) {
                            const ds = dateRange.date_start.split('-');
                            const ms = parseInt(ds[1]) - 1;
                            const de = dateRange.date_end.split('-');
                            const me = parseInt(de[1]) - 1;
                            return ['Logs by Asset', bteFormatDate(new Date(ds[0], ms, ds[2]), 'M j, Y') +
                                ' through ' + bteFormatDate(new Date(de[0], me, de[2]), 'M j, Y')];
                        },
                        align: 'center',
                        position: 'top',
                        font: function (context) {
                            var w = context.chart.width;
                            return {
                                size: w < 512 ? 14 : 16,
                                weight: 'bolder',
                            };
                        },
                        padding: function (context) {
                            var w = context.chart.width;
                            return {
                                top: w < 512 ? 10 : 12,
                                bottom: 0
                            };
                        },
                    },
                    legend: {
                        position: 'bottom',
                        align: 'middle',
                        // onClick: function (event, legendItem, legend) {
                        //     console.log(dateRange);
                        //     console.log('good', goodLogs);
                        //     console.log('bad', badLogs);
                        //     console.log('context/event', event);
                        //     console.log('context/legendItem', legendItem);
                        //     console.log('context/legend', legend);
                        //     legend.chart.update();
                        //     return false;
                        // },
                        // onHover: function (event, legendItem, legend) {
                        //     const activeElement = {
                        //         datasetIndex: 0,
                        //         index: legendItem.index
                        //     };
                        //     legend.chart.tooltip.setActiveElements([activeElement]);
                        //     legend.chart.update();
                        // }
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                        titleAlign: 'center',
                        position: 'bottom',
                        callbacks: {
                            title: function (context) {
                                if (!context || context.length == 0) return null;
                                const titleId = context[0].label;
                                if (Object.keys(inspectionLogs).includes(titleId)) {
                                    const theLog = inspectionLogs[titleId];
                                    return [theLog.name,
                                    theLog.number,
                                    theLog.resin,
                                    'Rev ' + theLog.rev, ''];
                                }
                                return null;
                            },
                        },
                        filter: function (tooltipItem, index, tooltipitemarray, context) {
                            if (tooltipItem && tooltipItem.label && tooltipItem.label.substring(0, 1) === '-') return false;
                            return true;
                        },
                    },
                },
                fallbackContent: (
                    <div className='w-full h-full flex justify-center align-center text-center'>
                        Bar Chart
                    </div>
                )
            }}
            data={{
                datasets: [{
                    label: 'Bad',
                    data: { ...badLogs },
                    backgroundColor: ['#FF0000'],
                    borderColor: ['#FF0000'],
                    borderWidth: 1,
                    datalabels: {
                    }
                },
                {
                    label: 'Good',
                    data: { ...goodLogs },
                    backgroundColor: ['#0000FF'],
                    borderColor: ['#0000FF'],
                    borderWidth: 1,
                    datalabels: {
                    }
                }]
            }}
        />
    )
}