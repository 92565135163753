import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { FaLock } from 'react-icons/fa';
import { Navigate, redirect } from 'react-router-dom';

import { logIn } from '../../slices/authenticationSlice'
import TextInput from '../../components/form/textInput';
import { assets } from '../../general/assets';
import { PostRequest } from '../../services/apiService';
import apiEndPoints from '../../services/apiEndPoints';
import Errors from '../../components/common/errors';
import { toggleLoading } from '../../slices/loadingSlice';
import Loading from '../../components/common/loading';
import PasswordInput from '../../components/form/passwordInput';

export default function Login() {

    const [errors, setErrors] = useState([])
    const dispatch = useDispatch()
    const loading = useSelector(state => state.loading)
    const authentication = useSelector(state => state.authentication)

    //
    const setLoading = (show = true) => {
        dispatch(toggleLoading(show))
    }

    const processLogin = async (values) => {
        setLoading(true)
        const resp = await PostRequest(apiEndPoints.authentication.login, { data: values });
        if (resp && resp.data) {
            if (resp.data.status === 'success') {
                setErrors([])
                localStorage.setItem('AppData', JSON.stringify({ user: resp.data.data, isLoggedIn: true }))
                dispatch(logIn({ user: resp.data.data, isLoggedIn: true }))
            } else {
                setErrors(resp.data.errors)
            }
        }
        setLoading(false)
    }

    return (
        <div className="flex min-h-screen items-center justify-center">
            {loading.showLoading && <Loading />}
            <div className="min-h-full">
                <div className="flex mx-auto w-md bg-white  shadow-1 drop-shadow-sm items-center max-w-screen-lg rounded-lg border border-gray-200 bg">
                    <div className='w-1/3 rounded h-full flex-col'>
                        <img src={assets.loginBgImg} className="rounded-l-lg h-full w-[120%]" />
                    </div>
                    <div className='hidden w-2/3 p-5v items-center h-ful px-16 md:block'>
                        <div className='mt-8 mb-6'>
                            <img src={assets.logo} className="w-[200px]" />
                        </div>
                        <h2 className='text-3xl font-semibold mb-5'>Log in to your account</h2>
                        {authentication && authentication.isLoggedIn && (
                            <Navigate to="/dashboard" replace={true} />
                        )}

                        {errors && errors.length > 0 &&
                            <div className="mb-5">
                                <Errors errors={errors} />
                            </div>
                        }

                        <div className="px-2">
                            <Formik
                                validationSchema={validationSchema}
                                initialValues={{ email: '', password: "" }}
                                onSubmit={(values, actions) => {
                                    processLogin(values)
                                }}
                            >
                                {(props) => (
                                    <Form>
                                        <Field name="email" label={{ for: "email", text: "Your email" }} input={{ placeholder: "Enter your email" }} component={TextInput} />
                                        <Field name="password" label={{ for: "password", text: "Your password" }} input={{ placeholder: "Enter your password" }} component={PasswordInput} />
                                        <div className='flex'>
                                            <div className=''></div>
                                        </div>
                                        <div className='mt-5 mb-16'>
                                            <button type="submit" className="inline-flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-md px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                                <FaLock /><span className='ml-2'>Login</span>
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
});