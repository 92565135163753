import React from 'react'

export default function Errors({ errors }) {
    return (
        <>
            {errors && errors.map((error, index) => {
                return <div className='m-2 bg-red-500 text-white rounded p-3' key={"error-" + index}>{error}</div>
            })
            }
        </>
    )
}
