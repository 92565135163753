import React, { useEffect, useState } from 'react'
import Header from '../components/layout/header'
import Sidebar from '../components/layout/sidebar'
import { useSelector } from 'react-redux'
import Loading from '../components/common/loading'

export default function MainLayout({ reloader = null, children }) {

  const loading = useSelector(state => state.loading)
  const menu = useSelector(state => state.menu)
  const menuWidth = menu.showFullMenu ? 'ml-64' : 'ml-12'

  return (
    <div>
      {loading.showLoading && <Loading />}
      <Header reloader={reloader} />
      <div className='h-screen bg-gray-50 pt-16'>
        <div className='min-h-full flex'>
          <Sidebar />
          <div className={menuWidth + " grow"}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
