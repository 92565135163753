import React from 'react'
import MainLayout from '../layouts/mainLayout'
import Breadcrumb from '../components/layout/breadcrumb'
import PageHeader from '../components/paritals/pageHeader'
import PageTitle from '../components/paritals/pageTitle'
import DashboardChart from '../components/dashboard/dashboardChart'

export default function Dashboard() {
  return (
    <MainLayout>
      <div className='p-3'>
        <Breadcrumb />

        <div>
          <PageHeader>
            <PageTitle title="Dashboard" />
            {/* <HeaderActionButton type="add" link="/standards/add" label="Add" /> */}
          </PageHeader>

          <div className={true ? "" : "relative overflow-x-auto shadow-md sm:rounded-lg"}>
            <DashboardChart />
          </div>

          <div className={"min-h-[35vh]"}>

          </div>
        </div>
      </div>
    </MainLayout>
  )
}
