export function bteFormatDate(date, format = 'M j, Y') {
    if (!date || !(date instanceof Date)) return 'date error';
    const month = {
        short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        long: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
    switch (format) {
        default:
            return 'format error';
        case 'M j, Y':
            return month.short[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
        case 'F j, Y':
            return month.long[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
    }
}
