import React, { useEffect, useState } from 'react'
import MainLayout from '../../layouts/mainLayout'
import Breadcrumb from '../../components/layout/breadcrumb'
import PageTitle from '../../components/paritals/pageTitle'
import PageHeader from '../../components/paritals/pageHeader'
import HeaderActionButton from '../../components/paritals/headerActionButton'
import { Field, Formik, Form } from 'formik'
import TextInput from '../../components/form/textInput'
import * as Yup from 'yup';
import apiEndPoints from '../../services/apiEndPoints'
import { toggleLoading } from '../../slices/loadingSlice'
import { useDispatch } from 'react-redux'
import { GetRequest, PostRequest } from '../../services/apiService'
import { useNavigate } from 'react-router-dom'
import Errors from '../../components/common/errors'
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa'
export default function UserProfile() {
    const [errors, setErrors] = useState([])
    const [initialValues, setInitialValues] = useState({})
    //
    const dispatch = useDispatch()
    const navigate = useNavigate();
    //
    const setLoading = (show = true) => {
        dispatch(toggleLoading(show))
    }
    const submitUserUpdate = async (data) => {
        setLoading(true)
        const formData = new FormData();
        Object.entries(data).map((input, index) => {
            formData.append(input[0], input[1])
        })
        //
        const resp = await PostRequest(apiEndPoints.auth.update, { data: formData })
        setLoading(false)
        if (resp.data.status == "success") {
            alert("User registered succcessfully.")
            navigate("/users/" + resp.data.data.id)
        } else {
            setErrors(resp.data.errors)
        }
    }
    //
    const getInitalValues = async () => {
        setLoading(true);
        const resp = await GetRequest(apiEndPoints.users.profile)
        setLoading(false);
        if (resp.data.status == "success") {
            console.log('initvalues', resp.data.data);
            setInitialValues(resp.data.data);
        } else {
            setErrors(resp.data.errors)
        }
    }
    //
    useEffect(() => {
        getInitalValues()
    }, [])
    return (
        <MainLayout>
            <div className='p-3'>
                <Breadcrumb links={breadcrumbLinks} />
                <div>
                    <PageHeader>
                        <PageTitle title="User Profile" />
                        <HeaderActionButton />
                    </PageHeader>

                    <div className="bg-white border border-gray-200 relative overflow-x-auto shadow-md sm:rounded-lg">
                        <div className="p-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <Errors errors={errors} />
                            <div className='md:flex'>
                                <div className='w-full grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3'>
                                    <div>
                                        <dt className='text-md font-semibold text-gray-600'>
                                            Name
                                        </dt>
                                        <dd className='text-md text-black my-2 ps-2'>{(initialValues.first_name && initialValues.last_name) ?
                                            initialValues.first_name + ' ' + initialValues.last_name : 'Loading...'
                                        }</dd>
                                    </div>
                                    <div>
                                        <dt className='text-md font-semibold text-gray-600'>
                                            Role
                                        </dt>
                                        <dd className='text-md text-black my-2 ps-2'>{initialValues.role ?? ''}</dd>
                                    </div>
                                    <div>
                                        <dt className='text-md font-semibold text-gray-600'>
                                            Department
                                        </dt>
                                        <dd className='text-md text-black my-2 ps-2'>{initialValues.department ?? '-'}</dd>
                                    </div>
                                    <div>
                                        <dt className='text-md font-semibold text-gray-600'>
                                            Position
                                        </dt>
                                        <dd className='text-md text-black my-2 ps-2'>{initialValues.position ?? '-'}</dd>
                                    </div>
                                    <div>
                                        <dt className='text-md font-semibold text-gray-600'>
                                            Manager
                                        </dt>
                                        <dd className='text-md text-black my-2 ps-2'>{(initialValues.manager_first_name && initialValues.manager_last_name) ?
                                            initialValues.manager_first_name + ' ' + initialValues.manager_last_name : 'No Assigned Manager'
                                        }</dd>
                                    </div>
                                    <div>
                                        <dt className='text-md font-semibold text-gray-600'>
                                            Phone Number
                                        </dt>
                                        <dd className='text-md text-black my-2 ps-2'>{initialValues.phone_number ?? '-'}</dd>
                                    </div>
                                    <div>
                                        <dt className='text-md font-semibold text-gray-600'>
                                            Email
                                        </dt>
                                        <dd className='text-md text-black my-2 ps-2 flex'>{initialValues.email_verified ?
                                            <FaRegCheckSquare className="mt-[3px] mr-[8px]" /> : <FaRegSquare className="mt-[3px] mr-[8px]" />}{initialValues.email ?? '-'}</dd>
                                    </div>
                                    <div>
                                        <dt className='text-md font-semibold text-gray-600'>
                                            Employee Number
                                        </dt>
                                        <dd className='text-md text-black my-2 ps-2'>{initialValues.employee_number ?? '-'}</dd>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}
const breadcrumbLinks = [
    {
        href: "/users",
        label: "Users"
    },
    {
        href: "/profile",
        label: "Profile"
    }
]

