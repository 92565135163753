import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/mainLayout';
import Breadcrumb from '../../components/layout/breadcrumb';
import PageTitle from '../../components/paritals/pageTitle';
import PageHeader from '../../components/paritals/pageHeader';
import HeaderActionButton from '../../components/paritals/headerActionButton';
import { useDispatch } from 'react-redux';
import { toggleLoading } from '../../slices/loadingSlice';
import apiEndPoints from '../../services/apiEndPoints';
import { GetRequest, PostRequest } from '../../services/apiService';
import { Link } from 'react-router-dom';
import { FaCircle } from 'react-icons/fa';


export default function NotificationIndex() {
    const [notificationList, setNotificationList] = useState([]);
    const [listPage, setListPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [time, setTime] = useState(null);
    const [reloadHead, setReloadHead] = useState(0);

    const dispatch = useDispatch();
    const setLoading = (show = true) => {
        dispatch(toggleLoading(show))
    }

    const loadNotifications = async (pageNum = 1) => {
        setReloadHead(Math.max(1, (reloadHead + 1) % 5))
        const pageNumber = (pageNum < 1) ? 1 : pageNum;
        setListPage(pageNumber);
        setLoading(true);
        const resp = await GetRequest(apiEndPoints.notification.index + "?page=" + pageNumber.toString())
        setLoading(false);
        if (resp && resp.data && resp.data.status == "success") {
            setNotificationList(resp.data.data.list)
            setMaxPage(resp.data.data.pagelimit)
            setTime(resp.data.data.last_loaded)
            console.log('Notification list: ', notificationList)
        }
    }

    const markAllRead = async () => {
        setLoading(true);
        const resp = await PostRequest(apiEndPoints.notification.read + "/all", { time: time })
        setLoading(false);
        if (resp && resp.data && resp.data.status == "success") {
            loadNotifications(listPage);
            console.log('All Read, ', resp.data.data)
        }
    }

    useEffect(() => {
        loadNotifications(1);
    }, [])

    return (
        <MainLayout reloader={reloadHead}>
            <div className='p-3'>
                <Breadcrumb links={breadcrumbLinks} />
                <div>
                    <PageHeader>
                        <PageTitle title="Notifications" />
                        <HeaderActionButton type="function" link={() => { markAllRead() }} label="Mark all as read" />
                    </PageHeader>

                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 pr-2">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-3 py-3 text-center">
                                        Read
                                    </th>
                                    <th scope="col" className="px-6 py-3 w-full md:max-w-[70%] xl:max-w-[80%]">
                                        Message
                                    </th>
                                    <th scope="col" className="px-6 py-3 w-full">
                                        Time
                                    </th>
                                    <th scope="col" className="px-4 py-3">
                                        <span className="sr-only">View</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {notificationList &&
                                    (Object.keys(notificationList).length > 0) ?
                                    (Object.keys(notificationList).map((key) => {
                                        const val = notificationList[key];
                                        return (
                                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={key}>
                                                <td className="px-3 py-2 font-medium text-center text-gray-900 whitespace-nowrap dark:text-white">
                                                    {(!!val.isread) ? <FaCircle color="green" className='mx-auto' title={val.readat} /> : <FaCircle color="red" className='mx-auto' />}
                                                </td>
                                                <td className="px-6 py-2 w-full md:max-w-[70%] xl:max-w-[80%]">
                                                    {val.message}
                                                </td>
                                                <td className="px-6 py-2 w-full">
                                                    {val.created_at} <span className='text-xs'>({val.timegap})</span>
                                                </td>
                                                <td className="px-4 py-2 text-right">
                                                    <Link to={val.id + ""}>
                                                        <span className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Details</span>
                                                    </Link>
                                                </td>
                                            </tr>
                                        )
                                    })) :
                                    (<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={-1}>
                                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" colSpan={5}>No notifications found.</td></tr>)
                                }
                            </tbody>
                            <tfoot>
                                {maxPage >= 1 &&
                                    (<tr>
                                        <td colSpan="4" className="py-2 px-2 text-right">
                                            {(() => {
                                                let row = [];
                                                let minPg = (listPage > 3 && maxPage > 5) ? (listPage - 2 - (maxPage - listPage > 1 ? (0) : (2 - (maxPage - listPage)))) : 1;
                                                if (maxPage > 1) {
                                                    row.push(<button type="button" onClick={() => {
                                                        loadNotifications(1)
                                                    }}
                                                        className={"rounded px-1.5 py-0.5 mx-0.5 shadow hover:shadow-none rounded dark:text-white bg-gray-100 dark:bg-gray-800 "}>&#60;</button>);
                                                }
                                                for (let cnt = minPg;
                                                    cnt <= ((maxPage - minPg < 5) ? maxPage :
                                                        minPg + 4);
                                                    cnt++) {
                                                    row.push(<button type="button" onClick={() => {
                                                        loadNotifications(cnt)
                                                    }}
                                                        className={"rounded px-1.5 py-0.5 mx-0.5 shadow hover:shadow-none rounded dark:text-white " +
                                                            ((cnt === listPage) ? "bg-gray-300 dark:bg-gray-600" : "bg-gray-100 dark:bg-gray-800 ")}>{cnt}</button>);
                                                }
                                                if (maxPage > 1) {
                                                    row.push(<button type="button" onClick={() => {
                                                        loadNotifications(maxPage)
                                                    }}
                                                        className={"rounded px-1.5 py-0.5 mx-0.5 shadow hover:shadow-none rounded dark:text-white bg-gray-100 dark:bg-gray-800 "}>&#62;</button>);
                                                }
                                                return row;
                                            })()}
                                        </td>
                                    </tr>)
                                }
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}

const breadcrumbLinks = [
    {
        href: "/notifications",
        label: "Notifications"
    }
]
