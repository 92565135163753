import React from 'react'
import { NavLink, useNavigate } from "react-router-dom";

export default function HeaderActionButton({ type, link, label }) {
    const navigate = useNavigate();

    return (
        <div>
            {(() => {
                switch (type) {
                    case 'add':
                        return (<NavLink to={link}>
                            <button type='button' className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                {label ?? "Add New"}
                            </button>
                        </NavLink>)
                        break;
                    case 'function':
                        return (<button type="button" onClick={() => link()} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            {label ?? "Activate"}
                        </button>)
                        break;
                    default:
                        return (<button type="button" onClick={() => navigate(-1)} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            {label ?? "Back"}
                        </button>)
                        break;
                }
            })()}
            {/* {type == "add" ?
                <NavLink to={link}>
                    <button type='button' className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        {label ?? "Add New"}
                    </button>
                </NavLink>
                :
                <button type="button" onClick={() => navigate(-1)} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    {label ?? "Back"}
                </button>
            } */}
        </div>
    )
}
