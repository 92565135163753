import React, { useState } from 'react'
import MainLayout from '../../layouts/mainLayout'
import Breadcrumb from '../../components/layout/breadcrumb'
import PageTitle from '../../components/paritals/pageTitle'
import PageHeader from '../../components/paritals/pageHeader'
import HeaderActionButton from '../../components/paritals/headerActionButton'
import { Field, Formik, Form } from 'formik'
import TextInput from '../../components/form/textInput'
import FileInput from '../../components/form/fileInput'
import SelectInput from '../../components/form/selectInput'
import TextAreaInput from '../../components/form/textAreaInput'
import * as Yup from 'yup';
import InputField from '../../components/form/InputField'
import { createYupSchema } from '../../helpers/yupSchemaGenerator'
import apiEndPoints from '../../services/apiEndPoints'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toggleLoading } from '../../slices/loadingSlice'
import { ApiBaseURL, GetRequest, PostRequest } from '../../services/apiService'
import { useEffect } from 'react'
import Modal from '../../components/common/modal'

export default function AddLog({ showAddLog, setShowAddLog, standardId, formInputs, addToList, guidelines }) {
  const [inspectionObj, setValidationObj] = useState({})
  const [initialValues, setInitialValues] = useState({})
  const [errors, setErrors] = useState({})
  // const [yepSchema, setYepSchema] = useState()
  // const [validationSchema, setValidationSchema] = useState()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loading = useSelector(state => state.loading)

  let { id } = useParams();
  //
  const setLoading = (show = true) => {
    dispatch(toggleLoading(show))
  }

  const handleSubmit = async (values) => {
    setLoading(true);
    setErrors({});
    console.log('datavalues: ', { data: values })
    const resp = await PostRequest(apiEndPoints.inspectionLogs.add + "/" + id, { data: values });
    console.log('resp.data: ', resp.data)
    if (resp.data && resp.data.data) {
      if (resp.data.data.errors) {
        let apiErrors = {};
        resp.data.data.errors.map((theError, index) => {
          apiErrors[theError.key] = theError.message;
        })
        setErrors(apiErrors);
      }
      if (resp.data.data.log) {
        addToList(resp.data.data.log)
        setShowAddLog(false)
      }
    }
    setLoading(false);
  }

  const removeFromErrors = (toRemove) => {
    if (!(toRemove in errors)) return;
    let errorList = errors;
    delete errorList[toRemove];
    setErrors(errorList);
    console.log('To Remove from errors: ', toRemove, '\n new errors: ', errors)
  }


  //
  console.log(formInputs)
  const yepSchema = formInputs.reduce(createYupSchema, {});
  const validationSchema = Yup.object().shape(yepSchema);

  //
  console.log(validationSchema)
  //
  const getInitalValues = () => {
    const values = {}
    formInputs.map((input, index) => {
      values[input.input_id] = ""
    })
    // console.log(formInputs, values)

    setInitialValues({ ...values });
  }

  //
  useEffect(() => {
    getInitalValues()
  }, [formInputs])

  return (
    <Modal modalData={{ id: "addInput", title: "Add Log", maxWidthClass: "max-w-7xl" }} setShowModal={setShowAddLog} showModal={showAddLog}>
      <div className="bg-white border border-gray-200 relative overflow-x-auto shadow-md sm:rounded-lg pb-11">
        <div className="p-5 px-10 w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              handleSubmit(values)
            }}
          >
            {(props) => (
              <Form>
                <div className='grid grid-cols-1 gap-4 md:grid-cols-3'>
                  {
                    formInputs && formInputs.map((input, index) => {
                      return (
                        <InputField inputData={{
                          uuid: input.input_id,
                          title: input.input_name,
                          details: input.description,
                          placeholder: input.placeholder,
                          inputType: input.input_type,
                          variable: input.variable == 1,
                          required: input.required == 1,
                          allowInvalidData: input.allowInvalidData,
                          baseValue: input.base_value,
                          minVariation: input.min_variation,
                          maxVariaition: input.max_variation,
                          validationType: input.input_type,
                          validations: input.validations,
                          apiErrors: input.input_id in errors ? errors[input.input_id] : '',
                          apiErrorOnChange: () => { removeFromErrors(input.input_id) },
                          value: "",
                          fullWidth: true,
                          options: input?.select_options?.map((opt, index) => {
                            return {
                              label: opt,
                              value: opt
                            }
                          })
                        }} key={"log-inpt-field-" + index} />
                      )
                    })
                  }
                </div>
                <div className='my-5'>
                  <button type="submit" className="inline-flex items-center text-white bg-blue-700 font-semibold hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 rounded-lg text-md px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                    <span className='text-lg font-semibold'>Submit</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="pb-5 px-10 w-full text-sm text-left text-gray-500 dark:text-gray-400">
          {guidelines && (<div className='bg-white border border-gray-400 relative overflow-x-auto p-3'>
            <div className='flex'>
              <div className={(guidelines.image ? 'md:w-2/3' : 'w-full')}>
                <h3 className="font-semibold text-md mb-2">Details</h3>
                <p className='whitespace-pre-wrap'>{guidelines.guidelines ?? "N/A"}</p>
              </div>
              {guidelines.image &&
                (<div className='md:w-1/3'>
                  <h3 className="font-semibold text-md mb-2">Image</h3>
                  <img src={ApiBaseURL + guidelines.image} className='mx-auto rounded' />
                </div>)}
            </div>
          </div>)}
        </div>
      </div>
    </Modal>
  )
}
