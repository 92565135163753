import React from 'react'
import MainLayout from '../../layouts/mainLayout'
import Breadcrumb from '../../components/layout/breadcrumb'
import PageTitle from '../../components/paritals/pageTitle'
import PageHeader from '../../components/paritals/pageHeader'
import HeaderActionButton from '../../components/paritals/headerActionButton'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toggleLoading } from '../../slices/loadingSlice'
import { useState } from 'react'
import apiEndPoints from '../../services/apiEndPoints'
import { GetRequest } from '../../services/apiService'
import { useEffect } from 'react'

export default function StandardsLogs() {
  const [standards, setStandards] = useState([])
  const dispatch = useDispatch()
  const setLoading = (show = true) => {
    dispatch(toggleLoading(show))
  }

  const getStandards = async () => {
    setLoading(true)
    //const resp = await GetRequest(apiEndPoints.inspectionStandards.index)
    const resp = await GetRequest(apiEndPoints.inspectionLogs.standards)
    setLoading(false)
    if (resp && resp.data && resp.data.status == "success") {
      setStandards(resp.data.data)
    }
  }

  useEffect(() => {
    getStandards()
  }, [])


  return (
    <MainLayout>
      <div className='p-3'>
        <Breadcrumb links={breadcrumbLinks} />
        <div>
          <PageHeader>
            <PageTitle title="Inspection Standard Logs" />
          </PageHeader>

          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Part Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Part Number
                  </th>
                  <th scope="col" className="px-6 py-3">
                    RESIN
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Rev
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Created at
                  </th>
                  <th scope="col" className="px-6 py-3">
                    <span className="sr-only">Details</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {standards && standards.length > 0 ?
                  standards.map((standard, index) => {

                    return (
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={"standard-" + index}>
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {standard.part_name}
                        </th>
                        <td className="px-6 py-4">
                          {standard.part_number}
                        </td>
                        <td className="px-6 py-4">
                          {standard.resin}
                        </td>
                        <td className="px-6 py-4">
                          {standard.rev}
                        </td>
                        <td className="px-6 py-4">
                          {new Date(standard.created_at).toString()}
                        </td>
                        <td className="px-6 py-4 text-right">
                          <Link to={standard.id + ""}>
                            <span className="font-medium text-blue-600 dark:text-blue-500 hover:underline">View Logs</span>
                          </Link>
                        </td>
                      </tr>
                    )
                  })
                  : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" colSpan={5}>No records found.</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

const breadcrumbLinks = [
  {
    href: "/logs",
    label: "Inspection Logs"
  }
]
