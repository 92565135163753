import { Field } from 'formik'
import React from 'react'
import TextInput from './textInput'
import SelectInput from './selectInput'
import NumberInput from './numberInput'
import TextAreaInput from './textAreaInput'
import MultiSelectInput from './multiSelectInput'

export default function InputField({ inputData }) {
    switch (inputData.inputType) {
        default:
        case "text":
            return (<Field name={inputData.uuid} label={{ for: inputData.uuid, text: inputData.title }} input={{ placeholder: inputData.placeholder }} type={"text"} component={TextInput} />)
        case "text-area":
            return (<Field name={inputData.uuid} label={{ for: inputData.uuid, text: inputData.title }} input={{ placeholder: inputData.placeholder, fullWidth: inputData.fullWidth }} type={"text-area"} component={TextAreaInput} />)
        case "password":
            return (<Field name={inputData.uuid} label={{ for: inputData.uuid, text: inputData.title }} input={{ placeholder: inputData.placeholder }} type={"password"} component={TextInput} />)
        case "select":
            return (<Field name={inputData.uuid} label={{ for: inputData.uuid, text: inputData.title }} input={{ placeholder: inputData.placeholder, options: inputData.options }} component={SelectInput} />)
        case "number":
            return (<Field name={inputData.uuid} apiErrors={{ error: inputData.apiErrors, change: inputData.apiErrorOnChange }} label={{ for: inputData.uuid, text: inputData.title, apiError: inputData.apiErrors }} input={{ placeholder: inputData.placeholder }} component={NumberInput} />)
        case "date":
            return (<Field name={inputData.uuid} label={{ for: inputData.uuid, text: inputData.title }} input={{ placeholder: inputData.placeholder }} type={"date"} component={TextInput} />)
        case "time":
            return (<Field name={inputData.uuid} label={{ for: inputData.uuid, text: inputData.title }} input={{ placeholder: inputData.placeholder }} type={"time"} component={TextInput} />)
        case "datetime":
            return (<Field name={inputData.uuid} label={{ for: inputData.uuid, text: inputData.title }} input={{ placeholder: inputData.placeholder }} type={"datetime-local"} component={TextInput} />)
        case "multi-select":
            return (<Field name={inputData.uuid} label={{ for: inputData.uuid, text: inputData.title, hideCount: inputData.hideCount ?? false }} input={{ placeholder: inputData.placeholder, options: inputData.options }} component={MultiSelectInput} />)
    }
}
