import React from 'react'
import { HiHome } from 'react-icons/hi'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { GrHomeRounded } from 'react-icons/gr'

export default function Breadcrumb({ links }) {
  return (
    <div className='mb-3'>
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
          <li className="inline-flex items-center">
            <a href="/dashboard" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <GrHomeRounded className='mr-2' /> Dashboard
            </a>
          </li>
          {links && links.map((link, index) => {
            return (
              <li key={"bc-" + index}>
                <div className="flex items-center">
                  <MdKeyboardArrowRight />
                  <a href={link.href} className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">{link.label}</a>
                </div>
              </li>
            )
          })}

        </ol>
      </nav>

    </div>
  )
}
