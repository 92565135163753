import React from 'react'
import ReactLoading from 'react-loading';

export default function Loading() {
    return (
        <div className='fixed top-0 w-full h-full bg-black bg-opacity-25 z-[9999]'>
            <div className='flex items-center justify-center h-full'>
                <div className='bg-white rounded-xl p-5 h-32 w-32 text-center items-center justify-center'>
                    <div className='h-16 w-16 mx-auto'>
                        <ReactLoading type={"spinningBubbles"} color={"#000000"} height={30} />
                    </div>
                    <span className='block mt-3 font-bold'>Loading</span>
                </div>
            </div>
        </div>
    )
}
