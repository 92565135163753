import React, { useEffect, useState } from 'react'
import MainLayout from '../../layouts/mainLayout'
import Breadcrumb from '../../components/layout/breadcrumb'
import PageTitle from '../../components/paritals/pageTitle'
import PageHeader from '../../components/paritals/pageHeader'
import HeaderActionButton from '../../components/paritals/headerActionButton'
import { ApiBaseURL, GetRequest } from '../../services/apiService'
import { useParams } from 'react-router-dom'
import { toggleLoading } from '../../slices/loadingSlice'
import apiEndPoints from '../../services/apiEndPoints'
import { useDispatch } from 'react-redux'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AddLog from './addLog'
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa'

export default function LogDetails() {
  const [showAddLog, setShowAddLog] = useState(false)
  const [standardDetails, setStandardDetails] = useState({})
  const [formInputs, setFormInputs] = useState([])
  const [errors, setErrors] = useState([])
  const [loadedDetails, setLoadedDetails] = useState([])
  const [loadedLog, setLoadedLog] = useState(0)
  const [logList, setLogList] = useState([])

  const addLogToList = (log) => {
    if (logList) {
      setLogList([...logList, { ...log }])
    } else {
      setLogList([{ ...log }])
    }
  }

  //
  let { id } = useParams();

  //
  const dispatch = useDispatch()

  //
  const setLoading = (show = true) => {
    dispatch(toggleLoading(show))
  }

  //
  const loadStandardDetails = async () => {
    //
    setLoading()
    const resp = await GetRequest(apiEndPoints.inspectionLogs.index + "/" + id + "/form")
    setLoading(false)

    console.log(resp.data)
    //
    if (resp.data.status == "success") {
      setStandardDetails({ ...resp.data.data.standard })
      setFormInputs([...resp.data.data.standardInputs])
    } else {
      setErrors(resp.data.errors)
    }
  }


  const loadStandardLogDetails = async (logIDToGet) => {
    console.log(logIDToGet, 'gid');
    if (loadedLog[0] == logIDToGet) {
      setLoadedLog(-1);
      return; //don't reload a loaded one
    }
    setLoading()
    const resp = await GetRequest(apiEndPoints.inspectionLogs.details + "/" + id + '/' + logIDToGet)
    setLoading(false)

    //console.log('insplogdetail', resp.data)
    //
    if (resp.data.status == "success") {
      setLoadedDetails({ ...resp.data.data.information })
      setLoadedLog(resp.data.data.activeLog)
    } else {
      setErrors(resp.data.errors)
    }
  }

  //
  useEffect(() => {
    loadStandardDetails()
  }, [])

  useEffect(() => {
    setLogList([...(standardDetails?.newest_logs ?? [])])
  }, [standardDetails])

  return (
    <MainLayout>
      <div className='p-3'>
        <Breadcrumb links={breadcrumbLinks} />
        <div>
          <PageHeader>
            <PageTitle title="Inspection Standard Details" />
            <HeaderActionButton />
          </PageHeader>

          <Tabs>
            <TabList className='mb-0 border-b border-gray-400'>
              <Tab><h4 className='font-semibold'>Inspection Logs</h4></Tab>
              <Tab><h4 className='font-semibold'>Standard Details</h4></Tab>
              <Tab><h4 className='font-semibold'>Inspection Guidelines</h4></Tab>
            </TabList>
            <TabPanel>
              <div className='bg-white border border-gray-400 border-t-0 relative overflow-x-auto p-3 rounded-b-lg'>
                <div className='flex'>
                  <div className='grow'>
                    <h3 className='my-3 font-semibold text-lg'>Inspection Standard Logs</h3>
                  </div>
                  <div>
                    <button type='button' className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      data-modal-target="addInput" data-modal-toggle="addInput"
                      onClick={() => {
                        setShowAddLog(true)
                      }}
                    >
                      Add Log
                    </button>
                  </div>
                </div>
                <div className='w-full'>
                  {/* {standardDetails.logs && */}
                  {logList &&
                    <table className="w-full p-2 text-left text-gray-500 dark:text-gray-400 rounded">
                      <thead className="p-2 font-bold table text-gray-700 bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                        <tr key="0">
                          <th className="p-2 w-full max-w-[10%] md:max-w-[5%] xl:max-w-[2.5%]">
                            &nbsp;
                          </th>
                          <th className="p-2 w-full max-w-[10%] md:max-w-[5%] xl:max-w-[2.5%]">
                            Valid
                          </th>
                          <th className="p-2 w-full">
                            Submission Date
                          </th>
                          <th className="p-2 text-right">
                            &nbsp;
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {standardDetails.logs.map((item, index) => ( */}
                        {logList.length > 0 ? logList.map((item, index) => (
                          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index + 1}>
                            <td colSpan="4">
                              <table className='w-full'>
                                <tbody>
                                  <tr>
                                    <td className="py-1 px-2 w-full max-w-[10%] md:max-w-[5%] xl:max-w-[2.5%]">{index + 1}</td>
                                    <td className="py-1 px-2 w-full max-w-[10%] md:max-w-[5%] xl:max-w-[2.5%]"><div className='w-full flex justify-center'>{(item.isValid == 1) ? <FaRegCheckSquare /> : <FaRegSquare />}</div></td>
                                    <td className="py-1 pr-2 pl-4 w-full">{new Date(item.created_at).toString()}</td>
                                    <td className="py-1 px-2 text-right"><button onClick={() => { loadStandardLogDetails(item.id) }}>{loadedLog[0] == item.id ? "Less" : "Details"}</button></td>
                                  </tr>
                                  {
                                    loadedLog == item.id &&
                                    <tr>
                                      <td colSpan='4' className="pl-2">
                                        <div className="w-full pl-3 border-l border-b border-black border-solid">
                                          <table className="w-full">
                                            <thead>
                                              <tr className="text-gray-700 bg-gray-300 dark:bg-gray-700 dark:text-gray-400 font-bold">
                                                <td className="py-1 px-2 text-center w-full max-w-[8%] md:max-w-[4%] xl:max-w-[2%]">Valid?</td>
                                                <td className="py-1 px-2 pr-6">Name</td>
                                                <td className="py-1 px-2 text-left">Value</td>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {Object.keys(loadedDetails).map((key) => (
                                                <tr className="odd:bg-gray-100 even:bg-gray-200">
                                                  <td className="p-1 text-center w-full max-w-[8%] md:max-w-[4%] xl:max-w-[2%]"><div className='w-full flex justify-center'>{loadedDetails[key].isValid == '1' ? <FaRegCheckSquare /> : <FaRegSquare />}</div></td>
                                                  <td className="py-1 px-2 font-semibold pr-6">{loadedDetails[key].name}</td>
                                                  <td className="py-1 px-2 text-left">{loadedDetails[key].value}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      </td>
                                    </tr>
                                  }
                                </tbody>
                              </table>
                            </td>
                          </tr>)
                          //&& (console.log('item', item))
                        ) : (
                          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={0}>
                            <td colSpan="4">
                              <table className='w-full'>
                                <tbody>
                                  <tr>
                                    <td colSpan="4" className="py-1 px-2 w-full">No logs present.</td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>)}
                      </tbody>
                    </table>
                  }
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="bg-white border border-gray-400 border-t-0 relative overflow-x-auto ">
                <div className="p-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <div className='md:flex'>
                    <div className='md:w-3/5 grid grid-cols-3 gap-3'>
                      <div>
                        <dt className='text-md font-semibold text-gray-600'>
                          Part Name
                        </dt>
                        <dd className='text-md font-semibold text-black my-2'>{standardDetails.part_name}</dd>
                      </div>
                      <div>
                        <dt className='text-md font-semibold text-gray-600'>
                          Part Number
                        </dt>
                        <dd className='text-md font-semibold text-black my-2'>{standardDetails.part_number}</dd>
                      </div>
                      <div>
                        <dt className='text-md font-semibold text-gray-600'>
                          RESIN
                        </dt>
                        <dd className='text-md font-semibold text-black my-2'>{standardDetails.resin}</dd>
                      </div>
                      <div>
                        <dt className='text-md font-semibold text-gray-600'>
                          Rev
                        </dt>
                        <dd className='text-md font-semibold text-black my-2'>{standardDetails.rev}</dd>
                      </div>
                      <div>
                        <dt className='text-md font-semibold text-gray-600'>
                          Status
                        </dt>
                        <dd className='text-md font-semibold text-black my-2'>{standardDetails.status}</dd>
                      </div>
                      <div>
                        <dt className='text-md font-semibold text-gray-600'>
                          Created at
                        </dt>
                        <dd className='text-md font-semibold text-black my-2'>{new Date(standardDetails.created_at).toString()}</dd>
                      </div>
                      <div className='col-span-2'>
                        <dt className='text-md font-semibold text-gray-600'>
                          Details
                        </dt>
                        <dd className='text-md font-semibold text-black my-2'>{standardDetails.details}</dd>
                      </div>
                    </div>
                    <div className='md:w-2/5 justify-center'>
                      <h3 className='text-md font-semibold text-gray-600 px-1 mb-2'>Master Image</h3>
                      {standardDetails.master_image && <img src={ApiBaseURL + standardDetails.master_image} className='mx-auto rounded' />}
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className='bg-white border border-gray-400 border-t-0 relative overflow-x-auto p-3'>
                <div className='flex'>
                  <div className='md:w-2/3'>
                    <h3 className="font-semibold text-md mb-2">Details</h3>
                    <p className='whitespace-pre-wrap'>{standardDetails?.guide_line?.guidelines ?? "N/A"}</p>
                  </div>
                  <div className='md:w-1/3'>
                    <h3 className="font-semibold text-md mb-2">Image</h3>
                    {standardDetails.guide_line?.image && <img src={ApiBaseURL + standardDetails?.guide_line.image} className='mx-auto rounded' />}
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <AddLog showAddLog={showAddLog} setShowAddLog={setShowAddLog} formInputs={formInputs} addToList={addLogToList} guidelines={standardDetails?.guide_line} />
    </MainLayout>
  )
}

const breadcrumbLinks = [
  {
    href: "/logs",
    label: "Inspection Logs"
  },
  {
    label: "Show"
  }
]
