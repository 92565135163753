import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { bteFormatDate } from '../../../helpers/dateFormatter';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DashboardPieChart(
    data,
    ...props
) {
    const [percentages, setPercentages] = useState([100, 0]);
    const [quantities, setQuantities] = useState([1, 0]);
    const [dateRange, setDateRange] = useState({
        date_start: new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1).toISOString().split('T')[0],
        date_end: new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0).toISOString().split('T')[0]
    });

    useEffect(() => {
        if (data && data.logcounts && Array.isArray(data.logcounts) && data.logcounts.length === 2) {
            let bad = parseFloat(data.logcounts[0]);
            let total = parseFloat(data.logcounts[1]);
            if (Number.isNaN(bad) || Number.isNaN(total) || total === 0) {
                setPercentages([0, 0]);
                setQuantities([0, 0]);
                return;
            }
            let good = Math.max(total - bad, 0);
            setQuantities([bad, good]);
            bad = Math.round((bad / (total)) * 1000) / 10;
            good = 100.0 - bad;
            setPercentages([bad, good]);
        }
        if (data && data.date_range) {
            setDateRange(data.date_range)
        }
    }, [data.logcounts]);

    return (
        <Pie options={{
            animation: { duration: 0 },
            responsive: true,
            plugins: {
                title: {
                    display: true,
                    text: function (context) {
                        const ds = dateRange.date_start.split('-');
                        const ms = parseInt(ds[1]) - 1;
                        const de = dateRange.date_end.split('-');
                        const me = parseInt(de[1]) - 1;
                        return ['All Logs', bteFormatDate(new Date(ds[0], ms, ds[2]), 'M j, Y') +
                            ' through ' + bteFormatDate(new Date(de[0], me, de[2]), 'M j, Y')];
                    },
                    align: 'center',
                    position: 'top',
                    font: function (context) {
                        var w = context.chart.width;
                        return {
                            size: w < 512 ? 14 : 16,
                            weight: 'bolder',
                        };
                    },
                    padding: function (context) {
                        var w = context.chart.width;
                        return {
                            top: w < 512 ? 10 : 12,
                            bottom: 0
                        };
                    },
                },
                legend: {
                    position: 'right',
                    align: 'middle',
                    onClick: function (event, legendItem, legend) {
                        //console.log(dateRange);
                        legend.chart.update();
                        return false;
                    },
                    onHover: function (event, legendItem, legend) {
                        const activeElement = {
                            datasetIndex: 0,
                            index: legendItem.index
                        };
                        legend.chart.tooltip.setActiveElements([activeElement]);
                        legend.chart.update();
                    }
                },
                tooltip: {
                    titleAlign: 'center',
                    mode: 'nearest',
                    intersect: true,
                    callbacks: {
                        label: function (context) {
                            const index = context.dataIndex;

                            return '  ' + context.dataset.dataQuantity[index] + ' ( ' + context.dataset.data[index] + '% )';
                        }
                    },
                },
                datalabels: {
                    color: 'white',
                    textAlign: 'center',
                    labels: {
                        title: {
                            anchor: 'center',
                            align: 'center',
                            offset: 32,
                            font: function (context) {
                                var w = context.chart.width;
                                return {
                                    size: w < 512 ? 14 : 16,
                                    weight: 'bold',
                                };
                            },
                            formatter: function (value, context) {
                                if (value === 0 || value === '0') return ''
                                const index = context.dataIndex;
                                return context.chart.legend.legendItems[index].text + '\n';
                            }
                        },
                        label: {
                            anchor: 'center',
                            align: 'center',
                            offset: 32,
                            font: function (context) {
                                var w = context.chart.width;
                                return {
                                    size: w < 512 ? 12 : 14,
                                };
                            },
                            formatter: function (value, context) {
                                if (value === 0 || value === '0') return ''
                                const index = context.dataIndex;
                                return '\n ' + context.dataset.dataQuantity[index] + ' ( ' + value + '% ) ';
                            }
                        }
                    }
                }
            },
            fallbackContent: (
                <div className='w-full h-full flex justify-center align-center text-center'>
                    Pie Chart
                </div>
            )
        }}
            plugins={[ChartDataLabels]}
            data={{
                labels: ['Bad ', 'Good '],
                datasets: [{
                    label: 'Totals',
                    data: { ...percentages },
                    dataQuantity: { ...quantities },
                    backgroundColor: ['#FF0000', '#0000FF'],
                    borderColor: ['#FF0000', '#0000FF'],
                    borderWidth: 1,
                    datalabels: {
                    }
                },]
            }} />
    )
}