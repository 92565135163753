import React, { useState } from 'react'
import MenuItem from '../menu/menuItem'
import { RxContainer, RxDashboard, RxDimensions, RxEnter, RxLayers, RxRulerSquare } from 'react-icons/rx'
import { RiLineChartLine, RiUserLine } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import appDataHelper from '../../data/appDataHelper';

export default function Sidebar() {
    const menu = useSelector(state => state.menu)
    const currentUser = useSelector(state => state.authentication?.user)
    const menuWidth = menu.showFullMenu ? 'w-64' : 'w-12'
    return (
        <div className={menuWidth + ' bg-white overflow-hidden h-full border-r border-gray-300 fixed'}>
            <ul>
                {menuItems.map((item, index) => {
                    if (!item.accessibleTo.includes(currentUser?.role)) return;
                    return (
                        <MenuItem data={item} showFullMenu={menu.showFullMenu} key={"menuitem-" + index} />
                    )
                })}
            </ul>
        </div>
    )
}

const menuItems = [
    {
        name: "Dashboard",
        link: "/dashboard",
        icon: <RxDashboard />,
        accessibleTo: appDataHelper.pagePermissions.staff
    },
    {
        name: "Standards",
        link: "/standards",
        icon: <RxDimensions />,
        accessibleTo: appDataHelper.pagePermissions.admin
    },
    {
        name: "Inspection Logs",
        link: "/logs",
        icon: <RxLayers />,
        accessibleTo: appDataHelper.pagePermissions.staff
    },
    {
        name: "Reports",
        link: "/reports",
        icon: <RiLineChartLine />,
        accessibleTo: appDataHelper.pagePermissions.admin
    },
    {
        name: "Users",
        link: "/users",
        icon: <RiUserLine />,
        accessibleTo: appDataHelper.pagePermissions.manager
    },
    {
        name: "Logout",
        link: "/logout",
        icon: <RxEnter />,
        accessibleTo: appDataHelper.pagePermissions.staff
    },
]