import React, { useEffect, useState } from 'react'
import { Field, Formik, Form } from 'formik'
import TextInput from '../../../components/form/textInput'
import SelectInput from '../../../components/form/selectInput'
import TextAreaInput from '../../../components/form/textAreaInput'
import * as Yup from 'yup';
import Modal from '../../../components/common/modal'
import SelectOptions from '../../../components/form/selectOptions'
import { GetRequest, PostRequest } from '../../../services/apiService'
import apiEndPoints from '../../../services/apiEndPoints'
import { useDispatch, useSelector } from 'react-redux'
import { toggleLoading } from '../../../slices/loadingSlice'
import { useNavigate } from 'react-router-dom'
import Errors from '../../../components/common/errors'

export default function EditInput({ showEditInput, setShowEditInput, standardId, inputId, editList }) {
    const [selectOptions, setSelectOptions] = useState([])
    const [inputData, setInputData] = useState(null)
    const [errors, setErrors] = useState([])

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const loading = useSelector(state => state.loading)

    //
    const setLoading = (show = true) => {
        dispatch(toggleLoading(show))
    }

    const loadInputData = async () => {
        //
        setLoading()

        //
        const resp = await GetRequest(apiEndPoints.inspectionStandardInputs.index + "/" + standardId + "/" + inputId, {})
        if (resp && resp.data.status == "success") {
            setInputData({ ...resp.data.data });
            //
            const selected_options = resp.data.data?.select_options;
            if (selected_options && selected_options.length > 0) {
                let filteredSelectedOptions = [];
                selected_options.map((opt, index) => {
                    filteredSelectedOptions.push(opt.value);
                })
                //
                setSelectOptions(filteredSelectedOptions);
            } else {
                setSelectOptions([])
            }
        }

        setLoading(false)

    }

    const updateStandardInput = async (data) => {
        //
        setLoading()

        //
        const formData = {
            inspection_standard_id: standardId,
            title: data.name,
            input_name: data.name,
            placeholder: data.placeholder,
            sub_title: data.sub_title,
            input_type: data.inputType,
            required: data.required,
            variable: data.variable,
            base_value: data.baseValue,
            // variable: data.variable,
            min_variation: data.negativeAllowedVariation,
            max_variation: data.postiveAllowedVariation,
            status: data.status,
            description: data.details,
            select_options: selectOptions,
            allowInvalidData: data.allowInvalidData
        }

        //
        const resp = await PostRequest(apiEndPoints.inspectionStandardInputs.index + "/" + standardId + "/" + inputId, { data: formData })

        //
        setLoading(false)
        if (resp.data.status == "success") {
            setShowEditInput(false)
            alert("Standard Input update succcessfully.")
            editList(resp.data.data)
            navigate("/standards/" + standardId)
        } else {
            setErrors(resp.data.errors)
        }
    }

    //
    useEffect(() => {
        loadInputData()
    }, [inputId])


    return (
        <>
            {
                !loading.showLoading && inputData &&
                <Modal modalData={{ id: "addInput", title: "Edit - " + inputData.title }} setShowModal={setShowEditInput} showModal={showEditInput}>
                    <div className="p-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <Errors errors={errors} />
                        <Formik
                            initialValues={{
                                name: inputData.title,
                                placeholder: inputData.placeholder,
                                sub_title: inputData.sub_title,
                                details: inputData.description ?? "",
                                inputType: inputData.input_type,
                                required: inputData.required,
                                variable: inputData.variable,
                                baseValue: inputData.base_value ?? "",
                                allowInvalidData: inputData.allowInvalidData ?? "",
                                negativeAllowedVariation: inputData.min_variation ?? "",
                                postiveAllowedVariation: inputData.max_variation ?? "",
                                status: inputData.active == 1 ? "active" : "inactive",
                            }}
                            enableReinitialize={true}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                updateStandardInput(values)
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <div className='block sm:flex'>
                                        <div className='w-full sm:w-1/2 p-2'>
                                            <Field name="name" label={{ for: "name", text: "Name / Label" }} input={{ placeholder: "Enter name" }} component={TextInput} />
                                        </div>
                                        <div className='w-full sm:w-1/2 p-2'>
                                            <Field name="inputType" label={{ for: "inputType", text: "Type" }} input={{ placeholder: "Choose", options: typeOptions }} component={SelectInput} />
                                        </div>
                                    </div>
                                    {(props.values.inputType == "select" || props.values.inputType == "multi-select") &&
                                        <div className='block'>
                                            <SelectOptions options={selectOptions} setOptions={setSelectOptions} />
                                        </div>
                                    }
                                    <div className='block sm:flex'>
                                        <div className='w-full sm:w-1/2 p-2'>
                                            <Field name="required" label={{ for: "required", text: "Required" }} input={{ placeholder: "Choose", options: boolOptions }} component={SelectInput} />
                                        </div>
                                        {(props.values.inputType == "number") &&
                                            <div className='w-full sm:w-1/2 p-2'>
                                                <Field name="variable" label={{ for: "variable", text: "Variable" }} input={{ placeholder: "Choose", options: boolOptions }} component={SelectInput} />
                                            </div>
                                        }
                                    </div>
                                    {(props.values.variable == 1) &&
                                        <>
                                            <div className='block sm:flex'>
                                                <div className='w-full sm:w-1/2 p-2'>
                                                    <Field name="allowInvalidData" label={{ for: "allowInvalidData", text: "Allow Invalid data" }} input={{ placeholder: "Choose", options: boolOptions }} component={SelectInput} />
                                                    <span className="block text-xs">'Yes' must be chosen to submit forms with numbers outside the variation range. Otherwise, form submission will be rejected without sending a manager notification.</span>
                                                </div>
                                                <div className='w-full sm:w-1/2 p-2'>
                                                    <Field name="baseValue" label={{ for: "baseValue", text: "Base Value" }} input={{ placeholder: "Enter base value" }} component={TextInput} />
                                                </div>
                                            </div>
                                            <div className='block sm:flex'>
                                                <div className='w-full sm:w-1/2 p-2'>
                                                    <Field name="negativeAllowedVariation" label={{ for: "negativeAllowedVariation", text: "Negative Allowed Variation" }} input={{ placeholder: "Enter allowed negative variation" }} component={TextInput} />
                                                </div>
                                                <div className='w-full sm:w-1/2 p-2'>
                                                    <Field name="postiveAllowedVariation" label={{ for: "postiveAllowedVariation", text: "Postive Allowed Variation" }} input={{ placeholder: "Enter allowed postive variation" }} component={TextInput} />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className='block sm:flex'>
                                        <div className='w-full sm:w-1/2 p-2'>
                                            <Field name="placeholder" label={{ for: "placeholder", text: "Placeholder" }} input={{ placeholder: "Enter place holder" }} component={TextInput} />
                                        </div>
                                        <div className='w-full sm:w-1/2 p-2'>
                                            <Field name="sub_title" label={{ for: "sub_title", text: "Subtitle" }} input={{ placeholder: "Enter subtitle" }} component={TextInput} />
                                        </div>
                                    </div>
                                    <div className='block'>
                                        <div className='w-full p-2'>
                                            <Field name="details" label={{ for: "details", text: "Details" }} input={{ placeholder: "Enter details/comments" }} component={TextAreaInput} />
                                        </div>
                                    </div>
                                    <div className='block sm:flex'>
                                        <div className='w-full sm:w-1/2 p-2'>
                                            <Field name="status" label={{ for: "status", text: "Status" }} input={{ placeholder: "Choose Status", options: [{ label: "Active", value: "active" }, { label: "Inactive", value: "inactive" }] }} component={SelectInput} />
                                        </div>
                                    </div>
                                    <div className='mt-5 px-3'>
                                        <button type="submit" className="inline-flex items-center text-white bg-blue-700 font-semibold hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 rounded-lg text-md px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                            <span className='text-lg font-semibold'>Submit</span>
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Modal>
            }
        </>
    )
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter name'),
    inputType: Yup.string().required('Please choose inout type'),
    required: Yup.boolean().required('Please choose if this input is required or not'),
    variable: Yup.boolean().when("inputType", {
        is: (inputType) => inputType === "number",
        then: () => Yup.number().required('Please choose if this is a variable input')
    }),
    baseValue: Yup.number().when("variable", {
        is: (variable) => variable === 1,
        then: () => Yup.number().required('Please enter your base value')
    }),
    negativeAllowedVariation: Yup.number().when("variable", {
        is: (variable) => variable === 1,
        then: () => Yup.number().required('Please choose max allowed negative variation')
    }),
    postiveAllowedVariation: Yup.number().when("variable", {
        is: (variable) => variable === 1,
        then: () => Yup.number().required('Please choose max allowed postive variation')
    })
});

const typeOptions = [
    { label: "Number", value: "number" },
    { label: "Text", value: "text" },
    { label: "Text Area", value: "text-area" },
    { label: "Select", value: "select" },
    { label: "Multi-select", value: "multi-select" },
    { label: "Date", value: "date" },
    { label: "Time", value: "time" },
]

const boolOptions = [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 }
] 
