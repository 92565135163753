import React, { useState } from 'react'
import { MdClose } from 'react-icons/md'

export default function Modal({ children, modalData, showModal, setShowModal }) {
    const [closeModalOnMouseup, setCloseModalOnMouseup] = useState(false);
    return (
        <>
            {showModal &&
                <div id={modalData.id} tabIndex="-1" aria-hidden="true"
                    className="fixed top-0 left-0 right-0 z-50 bg-gray-800 bg-opacity-60 w-full p-4 md:inset-0 max-h-screen"
                    onMouseDown={(e) => { setCloseModalOnMouseup(true) }}
                    onMouseUp={(e) => { if (closeModalOnMouseup) { setCloseModalOnMouseup(false); setShowModal(false) } }} >
                    <div className={"relative w-full max-h-[90vh] mx-auto " + (modalData.maxWidthClass ?? "max-w-4xl")} onMouseDown={(e) => { e.stopPropagation() }}>
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl font-bold text-gray-900 dark:text-white px-3">
                                    {modalData.title}
                                </h3>
                                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal"
                                    onClick={() => {
                                        setCloseModalOnMouseup(false);
                                        setShowModal(false)
                                    }}
                                >
                                    <MdClose size="20" />
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <div className="overflow-x-hidden overflow-y-auto max-h-[650px]">
                                <div>
                                    {children}
                                </div>
                            </div>
                            {modalData.footer &&
                                <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                    {modalData.footer}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
