import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { bteFormatDate } from '../../../helpers/dateFormatter';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function DashboardLineChart(
    data,
    ...props
) {
    const [badLogs, setBadLogs] = useState([]);
    const [dateStart, setDateStart] = useState(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1).toISOString().split('T')[0]);
    const [dateEnd, setDateEnd] = useState(new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0).toISOString().split('T')[0]);

    const [dateRange, setDateRange] = useState([
        new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1).toISOString().split('T')[0],
        new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0).toISOString().split('T')[0]
    ]);

    const line_chart_colors = [
        '#ff7f00', //orange
        '#6aff00', //green
        '#0095ff', //blue
        '#aa00ff', //purple
        '#ffd400', //yellow-gold
        '#4f8f23', //dark-green
        '#00eaff', //light-blue
        '#ff00aa', //pink
        '#0040ff', //dark-blue
        '#ff0000', //red
    ]

    useEffect(() => {
        //console.log('linechart data change', data)
        if (data && data.date_range && data.date_range.date_start && data.date_range.date_end) {
            setDateStart(data.date_range.date_start)
            setDateEnd(data.date_range.date_end)

            let date_start_range = data.date_range.date_start.split('-');
            date_start_range[1] = parseInt(date_start_range[1]) - 1;
            date_start_range = new Date(date_start_range[0], date_start_range[1], date_start_range[2]);

            let day = date_start_range.getDay() || 7;
            if (day !== 1) date_start_range.setHours(-24 * (day - 1));

            let date_end_range = data.date_range.date_end.split('-');
            date_end_range[1] = parseInt(date_end_range[1]) - 1;
            date_end_range = new Date(date_end_range[0], date_end_range[1], date_end_range[2]);

            day = date_end_range.getDay() || 7;
            if (day !== 7) date_end_range.setHours(24 * (day - 1));

            let newDateRanges = [];
            let dateCounter = new Date(date_start_range);
            while ((dateCounter[Symbol.toPrimitive]('number')) <= (date_end_range[Symbol.toPrimitive]('number'))) {
                newDateRanges.push(dateCounter[Symbol.toPrimitive]('number'));

                dateCounter.setHours(168) // 24*7
            }

            setDateRange(newDateRanges);

            if (data.standards && Array.isArray(data.standards) &&
                data.standards.length > 0 && data.standards[0].id !== 0) {


                let newData = [];
                data.standards.forEach(function (element) {
                    let badLogs = {};
                    newDateRanges.forEach(function (key) {
                        badLogs[key] = 0;
                    })
                    element.logs.forEach(function (elem) {
                        if (!elem.valid) {
                            let weekStart = elem.date.split(' ')[0].split('-');
                            weekStart[1] = parseInt(weekStart[1]) - 1;
                            weekStart = new Date(weekStart[0], weekStart[1], weekStart[2]);

                            let weekStartDay = weekStart.getDay() || 7;
                            if (weekStartDay !== 1) weekStart.setHours(-24 * (weekStartDay - 1));

                            badLogs[weekStart[Symbol.toPrimitive]('number')] += 1;
                        }
                    })
                    newData.push({
                        id: element.id,
                        name: element.name,
                        bad_logs: badLogs
                    });
                });

                setBadLogs(newData);


            } else {
                setBadLogs([]);
            }
        }
    }, [data]);

    return (
        <Line options={{
            animation: { duration: 0 },
            responsive: true,
            plugins: {
                title: {
                    display: true,
                    text: function (context) {
                        const ds = dateStart.split('-');
                        const de = dateEnd.split('-');
                        const ms = parseInt(ds[1]) - 1;
                        const me = parseInt(de[1]) - 1;
                        return ['Bad Logs', bteFormatDate(new Date(ds[0], ms, ds[2])) +
                            ' through ' + bteFormatDate(new Date(de[0], me, de[2]))];
                    },
                    align: 'center',
                    position: 'top',
                    font: function (context) {
                        var w = context.chart.width;
                        return {
                            size: w < 512 ? 12 : 14,
                            weight: 'bolder',
                        };
                    },
                    padding: function (context) {
                        var w = context.chart.width;
                        return {
                            top: w < 512 ? 8 : 10,
                            bottom: w < 512 ? 18 : 21
                        };
                    },
                },
                legend: {
                    position: 'bottom',
                    align: 'middle',
                    labels: {
                        generateLabels: function (context) {
                            const defaultHandler = ChartJS.defaults.plugins.legend.labels.generateLabels;
                            let rv = defaultHandler(context);
                            if (rv.length < 1) return [];
                            let allObj = Object.assign({}, rv[0]);
                            allObj.datasetIndex = -1;
                            allObj.text = 'All';
                            let hideAll = 0;
                            let colorAll = 0;
                            rv.forEach(function (elem) {
                                if (elem.hidden) hideAll += 1;
                                colorAll += 1 - (context.data.datasets[elem.datasetIndex].order);
                            })
                            allObj.hidden = (hideAll === rv.length);
                            if (colorAll === 0) {
                                allObj.fillStyle = 'darkgrey';
                                allObj.strokeStyle = 'darkgrey';
                            } else {
                                allObj.fillStyle = 'black';
                                allObj.strokeStyle = 'black';
                            }
                            rv.push(allObj);
                            return rv;
                        },
                    },
                    onClick: function (event, legendItem, legend) {
                        const index = legendItem.datasetIndex;
                        const ci = legend.chart;
                        if (index < 0) {
                            if (event.native.altKey || event.native.shiftKey) {
                                if (legendItem.hidden) {
                                    ci.data.datasets.forEach(function (val, dsindex) {
                                        ci.show(dsindex);
                                        ci.legend.legendItems[dsindex].hidden = false;
                                    });
                                    legendItem.hidden = false;
                                } else {
                                    ci.data.datasets.forEach(function (val, dsindex) {
                                        ci.hide(dsindex);
                                        ci.legend.legendItems[dsindex].hidden = true;
                                    });
                                    legendItem.hidden = true;
                                }
                            } else {
                                let selectedItems = 0;
                                ci.data.datasets.forEach(function (elem) { selectedItems += 1 - elem.order; });
                                let counterIndex = 0;
                                if (selectedItems > 0) {
                                    while (counterIndex < ci.data.datasets.length) {
                                        if (ci.data.datasets[counterIndex].order !== 1) {
                                            ci.data.datasets[counterIndex].backgroundColor = 'grey';
                                            ci.data.datasets[counterIndex].borderColor = 'grey';
                                            ci.data.datasets[counterIndex].order = 1;
                                        } else {
                                            counterIndex++;
                                        }
                                    }
                                } else {
                                    for (counterIndex = 0; counterIndex < ci.data.datasets.length; counterIndex++) {
                                        ci.data.datasets[counterIndex].backgroundColor = line_chart_colors[counterIndex] ?? 'black';
                                        ci.data.datasets[counterIndex].borderColor = line_chart_colors[counterIndex] ?? 'black';
                                        ci.data.datasets[counterIndex].order = 0;
                                    }
                                }
                            }
                            ci.update();
                            return;
                        }

                        if (ci.data.datasets[index]) {
                            if (event.native.altKey || event.native.shiftKey) {
                                //alt key is pressed, cross values out

                                if (ci.isDatasetVisible(index)) {
                                    ci.hide(index);
                                    legendItem.hidden = true;
                                } else {
                                    ci.show(index);
                                    legendItem.hidden = false;
                                }

                            } else { // alt not pressed

                                if (ci.data.datasets[index].order === 0) {
                                    ci.data.datasets[index].backgroundColor = 'grey';
                                    ci.data.datasets[index].borderColor = 'grey';
                                    ci.data.datasets[index].order = 1;
                                } else {
                                    ci.data.datasets[index].backgroundColor = line_chart_colors[index] ?? 'black';
                                    ci.data.datasets[index].borderColor = line_chart_colors[index] ?? 'black';
                                    ci.data.datasets[index].order = 0;
                                }
                            }
                        }
                        ci.update();

                        //console.log('context', legend.chart)
                        return true;
                    },
                    onHover: function (event, legendItem, legend) {
                        const ci = legend.chart;
                        legend.chart.tooltip.setActiveElements([]);
                        ci.update();
                        return true;
                    }
                },
                tooltip: {
                    mode: 'index',
                    intersect: false,
                    titleAlign: 'center',
                    position: 'bottom',
                    callbacks: {
                        title: function (context) {
                            const oLabel = context[0].label;
                            if (oLabel.includes('Week starting on,')) {
                                let nLabel = oLabel.split('Week starting on,');
                                nLabel[0] = 'Week starting on';
                                nLabel.push('');
                                return nLabel;
                            }
                            return oLabel;
                        },
                        label: function (context) {
                            const index = context.dataIndex;
                            return ' ' + context.dataset.full_name_label + ': ' + context.dataset.data[index];
                        }
                    },
                },
            },
            fallbackContent: (
                <div className='w-full h-full flex justify-center align-center text-center'>
                    Line Chart
                </div>
            ),
            scales: {
                y: {
                    beginAtZero: true,
                    offset: true,
                    ticks: {
                        callback: function (label, index, labels) {
                            //Returns last label and all whole numbers
                            if (Math.floor(label) === label || index === labels.length - 1) {
                                return label;
                            }
                        }
                    },
                },
                x: {
                    offset: true
                }
            }
        }}
            //plugins={[ChartDataLabels]}
            data={{
                labels: (() => {
                    let labels = [];
                    Object.keys(dateRange).forEach(function (key) {
                        const labelDate = new Date(dateRange[key]);
                        labels.push(['Week starting on', bteFormatDate(labelDate, 'M j, Y')])
                    });
                    return labels;
                })(),
                datasets:
                    badLogs.map((standard_information, index) => {

                        let retVal = {
                            borderColor: line_chart_colors[index] ?? 'black',
                            borderWidth: 1,
                            backgroundColor: line_chart_colors[index] ?? 'black'
                        }
                        retVal.data =
                            Object.keys(standard_information.bad_logs).map((key) => {
                                return standard_information.bad_logs[key];
                            });
                        retVal.borderWidth = 3;
                        retVal.order = 0;

                        retVal.label = (standard_information.name && standard_information.name.length > 15) ?
                            standard_information.name.substring(0, 15) + '...' :
                            standard_information.name;

                        retVal.full_name_label = standard_information.name;

                        return retVal;
                    })
            }} />
    )
}