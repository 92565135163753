import React from 'react'

export default function FileInput({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) {
  return (
    <div className="w-full mb-3">
      <label htmlFor={props.label.for} className={props.label.classes ?? "block mb-2 text-sm  font-semibold text-gray-900 dark:text-white"}>{props.label.text}</label>
      <input {...props.field} id={props.input.id} type="file"
        className={props.input.classes ?? " p-1 h-12 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"}
        placeholder={props.input.placeholder ?? ""} onChange={(e) => {
          setFieldValue(field.name, e.target.files[0])
        }} />
      {touched[field.name] &&
        errors[field.name] && <div className="text-red-500">{errors[field.name]}</div>}
    </div>
  )
}
