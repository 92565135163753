import React, { useEffect, useState } from 'react'
import MainLayout from '../../../layouts/mainLayout'
import Breadcrumb from '../../../components/layout/breadcrumb'
import PageTitle from '../../../components/paritals/pageTitle'
import PageHeader from '../../../components/paritals/pageHeader'
import HeaderActionButton from '../../../components/paritals/headerActionButton'
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { Field, Formik, Form } from 'formik'
import { GetRequest, PostRequest } from '../../../services/apiService'
import apiEndPoints from '../../../services/apiEndPoints'
import { useDispatch, useSelector } from 'react-redux'
import { toggleLoading } from '../../../slices/loadingSlice'
import { createYupSchema } from '../../../helpers/yupSchemaGenerator'
import InputField from '../../../components/form/InputField'
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa'

export default function LogDates() {
  const [logs, setLogs] = useState([])
  const [initialValues, setInitialValues] = useState({})


  const dispatch = useDispatch()
  const setLoading = (show = true) => {
    dispatch(toggleLoading(show))
  }

  const getLogsBetween = async (data) => {
    setLoading(true)
    const formData = new FormData();
    Object.entries(data).map((input, index) => {
      formData.append(input[0], input[1])
    })

    const resp = await PostRequest(apiEndPoints.reports.logDates, { data: formData })
    setLoading(false)
    if (resp && resp.data && resp.data.status == "success") {
      setLogs(resp.data.data)
    }
  }


  const formInputs = [
    {
      input_id: 1,
      title: "After",
      uuid: "datetime_start",
      details: "Enter datetime to start search from",
      inputType: 'datetime',
      required: true,
      validationType: "datetime",
      validations: [
        {
          type: "required",
          params: ["Start datetime is required"]
        }
      ]
    },
    {
      input_id: 2,
      title: "Before",
      uuid: "datetime_end",
      details: "Enter datetime to end search at",
      inputType: 'datetime',
      required: true,
      validationType: "datetime",
      validations: [
        {
          type: "required",
          params: ["End datetime is required"]
        }
      ]
    },
  ]

  const yepSchema = formInputs.reduce(createYupSchema, {});
  const validationSchema = Yup.object().shape(yepSchema);



  useEffect(() => {
    setLogs([])
    //getLogs()
  }, [])

  return (
    <MainLayout>
      <div className='p-3'>
        <Breadcrumb links={breadcrumbLinks} />
        <div>
          <PageHeader>
            <PageTitle title="Log Dates" />
            {/* <HeaderActionButton type="add" link="/standards/add" label="Add" /> */}
          </PageHeader>

          <div className={true ? "" : "relative overflow-x-auto shadow-md sm:rounded-lg"}>
            {(!logs || logs.length < 1) ? (<Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={(data, actions) => {
                getLogsBetween(data)
              }}
            >
              {(props) => (
                <Form>
                  <div className='grid grid-cols-1 gap-4 md:grid-cols-2'>
                    {
                      formInputs && formInputs.map((input, index) => {
                        return <InputField inputData={input} key={"dt-inpt-field-" + index} />
                      })}
                  </div>
                  <div className='mt-5 mb-16'>
                    <button type="submit" className="inline-flex items-center text-white bg-blue-700 font-semibold hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 rounded-lg text-md px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                      <span className='text-lg font-semibold'>Submit</span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>) :
              (
                <div className="w-full">
                  <table className="w-full">
                    <thead>
                      <tr className='text-left border-b border-black border-solid solid bg-gray-200'>
                        <th className='p-1'>
                          Log time
                        </th>
                        <th className='p-1 text-center'>
                          Valid?
                        </th>
                        <th className='p-1'>
                          Log maker
                        </th>
                        <th className='p-1'>
                          Part
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(logs).map((key) => {
                        let thislog = logs[key];
                        return (
                          <React.Fragment key={key}>
                            <tr className='bg-white'>
                              <td className='p-1'>
                                {new Date(thislog.created_at).toString()}
                              </td>
                              <td className='p-1'>
                                <div className='w-full flex justify-center'>{thislog.isValid == '1' ? <FaRegCheckSquare /> : <FaRegSquare />}</div>
                              </td>
                              <td className='p-1'>
                                {thislog.created_by?.first_name} {thislog.created_by?.last_name}
                              </td>
                              <td className='p-1'>
                                <Link to={"/logs/" + thislog.inspection_id}>
                                  {thislog.inspection_standard?.part_name}, Rev {thislog.inspection_standard?.rev ?? 1} ({thislog.inspection_standard?.part_number}) {thislog.inspection_standard?.resin}
                                </Link>
                              </td>
                              {console.log(thislog)}
                            </tr>
                            <tr className="bg-gray-100">
                              <td colSpan="4" className="p-1">
                                <div className="w-full pl-3 border-l border-b border-black border-solid">
                                  <table className='w-full'>
                                    <thead>
                                      <tr className="text-gray-700 bg-gray-300 dark:bg-gray-700 dark:text-gray-400 font-bold">
                                        <th className="py-1 px-2 text-center w-full max-w-[8%] md:max-w-[4%] xl:max-w-[2%]">Valid?</th>
                                        <th className="py-1 px-2 pr-6 text-left">Name</th>
                                        <th className="py-1 px-2 text-left">Value</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Object.keys(thislog.data).map((datakey) => (
                                        <tr className="odd:bg-gray-100 even:bg-gray-200" key={datakey}>
                                          <td className="p-1 text-center w-full max-w-[8%] md:max-w-[4%] xl:max-w-[2%]"><div className='w-full flex justify-center'>{thislog.data[datakey]?.isValid == '1' ? <FaRegCheckSquare /> : <FaRegSquare />}</div></td>
                                          <td className="py-1 px-2 font-semibold pr-6">{thislog.data[datakey]?.input?.input_name}</td>
                                          <td className="py-1 px-2 text-left">{thislog.data[datakey]?.meta_value}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

const breadcrumbLinks = [
  {
    href: "/reports",
    label: "Reports"
  },
  {
    href: "/reports/logDates",
    label: "Log Dates"
  }
]
