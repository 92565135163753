import React, { useEffect, useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export default function PasswordInput({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) {
  const [type, setType] = useState('password')

  function togglePassword() {
    if (type == 'password') {
      setType('text');
    } else {
      setType('password');
    }
  }

  return (
    <div className="w-full mb-3">
      <label htmlFor={props.label.for} className={props.label.classes ?? "block mb-2 text-sm font-medium text-gray-900 dark:text-white"}>{props.label.text}</label>
      <div className={props.input.boxclasses ?? "w-full flex rounded-lg border border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-700 focus-within:ring-blue-500 focus-within:border-blue-500 dark:focus-within:ring-blue-500 dark:focus-within:border-blue-500 "}>
        <input {...field} id={props.input.id} type={type}
          className={props.input.classes ?? " h-12 bg-transparent border-0 focus:ring-0 text-gray-900 text-sm block w-full p-2.5 dark:placeholder-gray-400 dark:text-white"}
          placeholder={props.input.placeholder ?? ""} key={field.name} autoComplete='new-password' />
        <button type="button" onClick={togglePassword} tabIndex="-1" className="w-[25px] h-12 bg-gray-200 dark:bg-gray-500 rounded-r-lg flex justify-center">{type == 'password' ? <FaEye className="my-auto" /> : <FaEyeSlash className="my-auto" />}</button>
      </div>
      {touched[field.name] &&
        errors[field.name] && <div className="text-red-500 text-sm">{errors[field.name]}</div>}
    </div>
  )
}
