import { createSlice } from '@reduxjs/toolkit'

export const menuSlice = createSlice({
  name: 'authentication',
  initialState: {
    showFullMenu:false
  },
  reducers: {
    toggle: (state) => {
      state.showFullMenu = !state.showFullMenu
    }
  }
})

// Action creators are generated for each case reducer function
export const { toggle } = menuSlice.actions

export default menuSlice.reducer
