import { configureStore } from '@reduxjs/toolkit'
import authenticationSlice from '../slices/authenticationSlice'
import menuSlice from '../slices/menuSlice'
import loadingSlice from '../slices/loadingSlice'

let preloadedState
const persistedDataString = localStorage.getItem('AppData')
const appData = JSON.parse(persistedDataString);

if (persistedDataString) {
  preloadedState = {
    authentication: {
      user: appData.user,
      isLoggedIn: appData.isLoggedIn
    }
  }
}

export default configureStore({
  reducer: {
    loading: loadingSlice,
    menu: menuSlice,
    authentication: authenticationSlice,
  },
  preloadedState: preloadedState
})