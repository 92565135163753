import React, { useState } from 'react'
import { assets } from '../../general/assets'
import { HiOutlineMenuAlt1, HiBell } from 'react-icons/hi'
import { useDispatch } from 'react-redux'
import { toggle } from '../../slices/menuSlice'
import NotificationsDropdown from '../common/notificationsDropdown'
import UserMenu from '../common/userMenu'

export default function Header({ reloader = null }) {
  const [showHeaderDropDown, setShowHeaderDropDown] = useState("")

  const dispatch = useDispatch()
  const toggleMenu = () => {
    dispatch(toggle())
  }

  return (
    <nav className='fixed w-full bg-white z-50'>
      <div className=' p-2 border-b border-gray-200'>
        <div className='flex justify-between items-center'>
          <div className='flex items-center'>
            <button className='mr-4' onClick={() => { toggleMenu() }}><HiOutlineMenuAlt1 size="28" /> </button>
            <a href="/dashboard"><img src={assets.logo} className=' w-[100px]' /></a>
          </div>
          <div className='flex  items-center'>
            <NotificationsDropdown reloader={reloader} showHeaderDropDown={showHeaderDropDown} setShowHeaderDropDown={setShowHeaderDropDown} />
            <UserMenu showHeaderDropDown={showHeaderDropDown} setShowHeaderDropDown={setShowHeaderDropDown} />
          </div>
        </div>
      </div>
    </nav>
  )
}
