export default {
    inspectionStandards: {
        index: "inspection-standards",
        add: "inspection-standards/add",
        updateGuidlines: "inspection-standards/guidelines"
    },
    inspectionStandardInputs: {
        index: "inspection-standard-inputs",
        add: "inspection-standard-inputs/add"
    },
    inspectionLogs: {
        index: "inspection-logs",
        standards: "inspection-logs/standards",
        add: "inspection-logs/add",
        details: "inspection-logs/details"
    },
    users: {
        index: "users",
        add: "users/add",
        profile: "users/profile",
        managerlist: "users/getManagerList",
    },
    authentication: {
        login: "auth/login",
        logout: "auth/logout",
        refresh: "auth/refresh",
        me: "auth/me",
    },
    notification: {
        index: "notifications",
        short: "notifications/short",
        read: "notifications/read",
    },
    reports: {
        logDates: "reports/logDates",
        getReports: "reports/get",
        getReportFiles: "reports/getFile",
        assets: "reports/assets",
    },
    dashboard: {
        getAssets: "dashboard/assets",
        getChartData: "dashboard/chart",
    }

}