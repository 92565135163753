import React, { useEffect, useState } from 'react'
import MainLayout from '../../layouts/mainLayout'
import Breadcrumb from '../../components/layout/breadcrumb'
import PageTitle from '../../components/paritals/pageTitle'
import PageHeader from '../../components/paritals/pageHeader'
import HeaderActionButton from '../../components/paritals/headerActionButton'
import StandardInputs from './inputs/standardInputs'
import AddInput from './inputs/addInput'
import { toggleLoading } from '../../slices/loadingSlice'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ApiBaseURL, GetRequest } from '../../services/apiService'
import apiEndPoints from '../../services/apiEndPoints'
import Errors from '../../components/common/errors'
import EditInput from './inputs/editInput'
import GuideLineDetails from './guidline/guideLineDetails'

export default function StandardDetails() {
  const [activeInput, setActiveInput] = useState(null)
  const [showAddInput, setShowAddInput] = useState(false)
  const [showEditInput, setShowEditInput] = useState(false)
  const [standardDetails, setStandardDetails] = useState({})
  const [errors, setErrors] = useState([])

  let { id } = useParams();
  //
  const dispatch = useDispatch()
  //
  const setLoading = (show = true) => {
    dispatch(toggleLoading(show))
  }

  const addToInputs = (input) => {
    console.log('add to input, ', input)
    setStandardDetails({ ...standardDetails, inputs: [...standardDetails.inputs, input] })
  }

  const editInput = (input) => {
    let oldInput = [];
    Object.keys(standardDetails.inputs).map((keyValue, ind) => {
      const val = standardDetails.inputs[keyValue];
      oldInput[keyValue] = (val.id == input.id) ? (input) : (val);
    })

    console.log('edit input, ', input);
    console.log('edit input 2, ', oldInput);
    setStandardDetails({ ...standardDetails, inputs: oldInput })
  }

  //
  const loadStandardDetails = async () => {
    //
    setLoading()
    const resp = await GetRequest(apiEndPoints.inspectionStandards.index + "/" + id)
    setLoading(false)
    if (resp.data.status == "success") {
      setStandardDetails({ ...resp.data.data })
    } else {
      setErrors(resp.data.errors)
    }
  }

  //
  const handleEditInput = (inputId) => {
    setActiveInput(inputId)
    setShowEditInput(true)
    setShowAddInput(false)
  }

  //
  useEffect(() => {
    loadStandardDetails()
  }, [])

  useEffect(() => {
    console.log('Standard Details: ', standardDetails)
  }, [standardDetails])

  return (
    <MainLayout>
      <div className='p-3'>
        <Breadcrumb links={breadcrumbLinks} />
        <div>
          <PageHeader>
            <PageTitle title="Standard Details" />
            <HeaderActionButton />
          </PageHeader>
          <div className="bg-white border border-gray-200 relative overflow-x-auto shadow-md sm:rounded-lg">
            <div className="p-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <Errors errors={errors} />
              <div className='md:flex'>
                <div className='md:w-3/5 grid grid-cols-3 gap-3'>
                  <div>
                    <dt className='text-md font-semibold text-gray-600'>
                      Part Name
                    </dt>
                    <dd className='text-md font-semibold text-black my-2'>{standardDetails.part_name}</dd>
                  </div>
                  <div>
                    <dt className='text-md font-semibold text-gray-600'>
                      Part Number
                    </dt>
                    <dd className='text-md font-semibold text-black my-2'>{standardDetails.part_number}</dd>
                  </div>
                  <div>
                    <dt className='text-md font-semibold text-gray-600'>
                      RESIN
                    </dt>
                    <dd className='text-md font-semibold text-black my-2'>{standardDetails.resin}</dd>
                  </div>
                  <div>
                    <dt className='text-md font-semibold text-gray-600'>
                      Rev
                    </dt>
                    <dd className='text-md font-semibold text-black my-2'>{standardDetails.rev}</dd>
                  </div>
                  <div>
                    <dt className='text-md font-semibold text-gray-600'>
                      Status
                    </dt>
                    <dd className='text-md font-semibold text-black my-2'>{standardDetails.status}</dd>
                  </div>
                  <div>
                    <dt className='text-md font-semibold text-gray-600'>
                      Created at
                    </dt>
                    <dd className='text-md font-semibold text-black my-2'>{new Date(standardDetails.created_at).toString()}</dd>
                  </div>
                  <div className='col-span-2'>
                    <dt className='text-md font-semibold text-gray-600'>
                      Details
                    </dt>
                    <dd className='text-md font-semibold text-black my-2'>{standardDetails.details}</dd>
                  </div>
                </div>
                <div className='md:w-2/5 justify-center'>
                  <h3 className='text-md font-semibold text-gray-600 px-1 mb-2'>Master Image</h3>
                  {standardDetails.master_image && <img src={ApiBaseURL + standardDetails.master_image} className='mx-auto rounded' />}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-5'>
          <GuideLineDetails standardId={standardDetails.id} guideline={standardDetails.guide_line} />
        </div>
        <div className='mt-5 mb-[5vh] md:mb-[15vh]'>
          <div className='flex'>
            <div className='grow'>
              <h3 className='my-3 font-semibold text-lg'>Standard Inputs</h3>
            </div>
            <div>
              <button type='button' className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                data-modal-target="addInput" data-modal-toggle="addInput"
                onClick={() => {
                  // console.log("here we are...")
                  setShowAddInput(true)
                }}
              >
                Add Input
              </button>
            </div>
          </div>

          <div>
            <StandardInputs inputs={standardDetails.inputs} handleEditInput={handleEditInput} />
          </div>
          <AddInput showAddInput={showAddInput} setShowAddInput={setShowAddInput} standardId={standardDetails.id} addToList={addToInputs} />
          <EditInput showEditInput={showEditInput} setShowEditInput={setShowEditInput} standardId={standardDetails.id} inputId={activeInput} editList={editInput} />
        </div>
      </div>
    </MainLayout>
  )
}

const breadcrumbLinks = [
  {
    href: "/standards",
    label: "Standards"
  },
  {
    label: "Show"
  }
]
