import React, { useEffect, useState } from 'react'
import { MultiSelect } from "react-multi-select-component";

export default function MultiSelectInput({
  field, // { name, value, onChange, onBlur }
  form,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) {
  const [selected, setSelected] = useState([]);

  function myOnChange(value) {
    setSelected(value);
    const justID = value.map((vlue) => {
      return vlue.value;
    })
    if (form) {
      let newVal = { ...form.values };
      newVal[field.name] = justID;
      form.setValues({ ...newVal });
    }
    console.log('changed, value', value);
  }

  useEffect(() => {
    let newSel = []
    props.input.options.forEach((option) => {
      if (option.selected && !newSel.includes(option.value)) newSel.push(option.value);
    });
    if (form && form.initialValues && form.initialValues[field.name]) {
      form.initialValues[field.name].forEach((defaultSelectedVal) => {
        if (!newSel.includes(defaultSelectedVal)) newSel.push(defaultSelectedVal);
      });
    }

    let selectedValues = [];
    props.input.options.forEach((option) => {
      if (newSel.includes(option.value)) {
        selectedValues.push({ value: option.value, label: option.label, disabled: option.disabled ?? false });
      }
    });
    setSelected(selectedValues);

    let newVal = { ...form.values };
    newVal[field.name] = newSel;
    form.setValues({ ...newVal });
  }, [form.initialValues]);

  const searchOptions = (options, filter) => {
    if (!filter) {
      return options;
    }
    let searchOptions = [];
    if (props.input.alteredInput) {
      options.forEach(function (option) {
        let newoption = JSON.parse(JSON.stringify(option));

        Object.keys(props.input.alteredInput).forEach(function (key) {
          const alterations = props.input.alteredInput[key];
          Object.keys(alterations).forEach(function (alterationKey) {
            const alteration = alterations[alterationKey];
            switch (alterationKey) {
              case 'replace':
                newoption.label = newoption.label.replace(alteration.old ?? '', alteration.new ?? '')
                break;
              case 'trimEnd':
                newoption.label = newoption.label.substring(0, newoption.label.length - alteration);
                break;
              default: break;
            }
          })
        })

        searchOptions.push(newoption);
      })
    } else {
      searchOptions = options;
    }

    let validValues = [];
    /**
     * @param {string} needle 
     * @param {string} haystack 
     * @returns {boolean}
     */
    const searchTermsMatch = ((needle, haystack) => {
      //V only if needle exists?
      if (!haystack) return false;
      // console.log('stmN', needle)
      // console.log('stmH', haystack)
      // console.log('stm_bools_exactM', (haystack.trim()).includes(needle.trim()))
      // console.log('stm_bools_lowercaseM', (haystack.toLowerCase().trim()).includes(needle.toLowerCase().trim()))
      if (props.input.caseSensitiveInput) {
        return (haystack.trim()).includes(needle.trim());
      } else {
        return (haystack.toLowerCase().trim()).includes(needle.toLowerCase().trim());
      }
    });
    if (props.input.alteredInputSearch) {

      const ais = props.input.alteredInputSearch;
      let searchTermEmptyWithSections = true;
      searchOptions.forEach(function (searchOption) {
        let addInput = false;

        const type = ais.type ?? '';
        switch (type) {
          case 'split':
            const allSearchFields = ais.splitChars ? searchOption.label.split(ais.splitChars) : [searchOption.label];
            searchTermEmptyWithSections = filter.includes(':');
            let customFilteredFields = (!!(ais.filterInputFields) && filter.includes(':'));
            if (customFilteredFields) {
              let filterInputFields = {};
              //check not getting invalid array value
              Object.keys(ais.filterInputFields).forEach(function (fifKey) {
                const filteredField = parseInt(ais.filterInputFields[fifKey]);
                if (!isNaN(filteredField) && filteredField < allSearchFields.length) {
                  filterInputFields[fifKey] = filteredField;
                }
              });
              if (Object.keys(filterInputFields).length > 0) {
                const allFilters = filter.split('&&');
                let filtersThatMatchFields = 0;
                allFilters.forEach(function (filtercheck) {
                  if (filtercheck.length > 0 && filtercheck.includes(':')) {
                    //labelled as a term
                    let currentFilter = filtercheck.split(':');
                    const fieldName = currentFilter[0];
                    currentFilter[0] = '';
                    if (currentFilter.length > 2) {
                      currentFilter = currentFilter.join(':');
                      currentFilter = currentFilter.length > 1 ? currentFilter.substring(1) : ''; //if is a jic
                    } else if (currentFilter.length > 1) {
                      currentFilter = currentFilter[1];
                    } else {
                      currentFilter = '';
                    }
                    if (currentFilter === '') {
                      if (Object.keys(filterInputFields).includes(fieldName.toLowerCase().trim())) {
                        //if it's a key, term isn't entered yet, don't flip searchTermEmptyWithSections
                        filtersThatMatchFields += 1;
                        return;
                      } else {
                        //not a key, so a search term using :
                        currentFilter = fieldName + ':';
                      }
                    }
                    searchTermEmptyWithSections = false;
                    if (Object.keys(filterInputFields).includes(fieldName.toLowerCase().trim())) {
                      //check if this key's field matches
                      const theField = allSearchFields[filterInputFields[fieldName.toLowerCase().trim()]];
                      if (searchTermsMatch(currentFilter, theField)) {
                        filtersThatMatchFields += 1;
                      }
                    } else {
                      //check any field matches
                      let filterMatchesField = false;
                      allSearchFields.forEach(function (possibleField) {
                        if (searchTermsMatch(currentFilter, possibleField)) {
                          filterMatchesField = true;
                        }
                      })
                      if (filterMatchesField) filtersThatMatchFields += 1;
                    }
                  } else {
                    //no sections, check any field matches
                    let filterMatchesField = false;
                    allSearchFields.forEach(function (possibleField) {
                      if (searchTermsMatch(filtercheck, possibleField)) {
                        filterMatchesField = true;
                      }
                    })
                    if (filterMatchesField) filtersThatMatchFields += 1;
                  }
                });
                addInput = (filtersThatMatchFields === allFilters.length); //all sub-filters match
              } else {
                customFilteredFields = false;
              }
            }
            if (!customFilteredFields) { //not else bc errors flip boolean
              let matchesField = false;
              allSearchFields.forEach(function (possibleField) {
                if (searchTermsMatch(filter, possibleField)) {
                  matchesField = true;
                }
              })
              addInput = matchesField;
            }

            break;
          default:
            addInput = (searchTermsMatch(filter, searchOption.label));
            break;
        }
        if (addInput) {
          validValues.push(searchOption.value);
        }

      })
      if (searchTermEmptyWithSections) {
        //after broken into sections all terms are empty strings
        return options;
      }

    } else {
      searchOptions.forEach(function (searchOption) {
        if (searchTermsMatch(filter, searchOption.label)) {
          validValues.push(searchOption.value);
        }
      });
    }

    return options.filter(({ value }) => value && validValues.includes(value));
  };

  const selectedValueRenderer = ((selected, vr_options) => {

    const labelAlterer = ((label) => {
      if ((props.input.customValueRenderer)) return (props.input.customValueRenderer(label));
      return label;
    })

    let itemName = 'items';
    if (props.label.text) {
      itemName = props.label.text.toLowerCase();
      if (!itemName || itemName.length < 1 || itemName === ':') itemName = 'items';
      if (itemName.slice(-1) === ':') {
        itemName = itemName.slice(0, -1);
      }
    }

    if (selected.length === vr_options.length) {
      return 'All ' + itemName + ' are selected.';
    }

    return selected.length ?
      selected.map(({ label }) => labelAlterer(label)).join(', ') :
      'No ' + itemName + ' are selected.'

  })

  return (
    <div className={"w-full mb-3" + (props.input.multiLineLabel ? " bte-mlinput" : "")}>
      <label htmlFor={props.label.for} className={props.label.classes ?? ((props.label.hideCount ? 'block ' : 'flex justify-between ') + "mb-2 text-sm font-medium text-gray-900 dark:text-white w-full")}
      >{props.label.text} {props.label.hideCount ? '' : (<span className='ml-auto'>({selected.length} selected)</span>)}</label>
      <MultiSelect
        id={props.input.id}
        filterOptions={searchOptions}
        valueRenderer={selectedValueRenderer}
        options={
          props.input.options ? (props.input.placeholder ? (props.input.options.map((option, index) => {
            return {
              value: option.value, label: option.label, disabled: option.disabled ?? false, key: "opt-" + index
            }
          }).unshift({ value: "", label: props.input.placeholder })) :
            (props.input.options.map((option, index) => {
              return {
                value: option.value, label: option.label, disabled: option.disabled ?? false
              }
            }))) : (props.input.placeholder ? [{ value: "", label: props.input.placeholder }] : [])}
        value={selected}
        onChange={myOnChange}
        hasSelectAll={false}
        className={props.input.classes ?? "h-12 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"}
      />
      {touched[field.name] &&
        errors[field.name] && <div className="text-red-500">{errors[field.name]}</div>}
    </div>
  )
}
