import React, { useEffect, useState } from 'react'

export default function TextInput({
  field, // { name, value, onChange, onBlur },
  form,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) {
  return (
    <div className="w-full mb-3">
      <label htmlFor={props.label.for} className={props.label.classes ?? "block mb-2 text-sm font-medium text-gray-900 dark:text-white"}>{props.label.text}</label>
      <input {...field} id={props.input.id} type={props.type}
        className={props.input.classes ?? " h-12 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"}
        placeholder={props.input.placeholder ?? ""} key={field.name} min={props.input.min} max={props.input.max} />
      {touched[field.name] &&
        errors[field.name] && <div className="text-red-500 text-sm">{errors[field.name]}</div>}
    </div>
  )
}
